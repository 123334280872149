import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

interface LoaderProps {
  size?: SizeProp;
}

const Loader: React.FC<LoaderProps> = ({
  size = '1x',
} : LoaderProps) => {
  return (
    <div className="text-primary text-center px-5 py-3">
      <FontAwesomeIcon icon={faCircleNotch} spin size={size} />
    </div>
  );
};

export default Loader;
