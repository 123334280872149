import { useToggle } from '@/hooks';
import { useUpdateSchedulerSignPermissions } from '@/hooks/sign';
import { useCreateUser } from '@/hooks/user';
import { IUserCreateRequest } from '@/types/user';
import { useEffect, useState } from 'react';

const useUserInviteModal = () => {
  const { show: showUserInviteModal, hide: hideUserInviteModal, isVisible: isUserInviteModalVisible } = useToggle();
  const [userForm, setUserForm] = useState<IUserCreateRequest | null>(null);

  const {
    createUser,
    createdUser,
    createUserIsLoading,
  } = useCreateUser();

  const {
    updateSchedulerSignPermissions,
    updatedPermissions,
    updateSchedulerSignPermissionsIsLoading,
  } = useUpdateSchedulerSignPermissions();

  const submitUserInvite = (user: IUserCreateRequest, companyId: string) => {
    setUserForm(user);

    createUser({
      ...user,
      schedulerSignPermissions: undefined,
      companyId,
    });
  };

  useEffect(() => {
    if (!createdUser) return;

    if (userForm && userForm.schedulerSignPermissions && userForm.schedulerSignPermissions.length > 0) {
      updateSchedulerSignPermissions({
        userId: createdUser.id,
        signIds: userForm.schedulerSignPermissions,
      });
    } else {
      hideUserInviteModal();
    }
  }, [createdUser]);

  useEffect(() => {
    if (!updatedPermissions) return;

    hideUserInviteModal();
  }, [updatedPermissions]);

  return {
    showUserInviteModal,
    hideUserInviteModal,
    isUserInviteModalVisible,
    submitUserInvite,
    createdUser,
    createUserIsLoading: createUserIsLoading || updateSchedulerSignPermissionsIsLoading,
  };
};

export default useUserInviteModal;
