import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useCallApi } from '@/hooks';
import { IUpdateSchedulerSignPermissionsRequest } from '@/types/sign';

const useUpdateSchedulerSignPermissions = () => {
  const [updatedPermissions, setUpdatedPermissions] = useState<Record<string, never> | null>(null);
  const [updateSchedulerPermissionsApiConfig, setUpdateSchedulerPermissionsApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateSchedulerSignPermissionsResponse,
    loading: updateSchedulerSignPermissionsIsLoading,
  } = useCallApi(updateSchedulerPermissionsApiConfig, {
    successMessage: 'Updated scheduler sign permissions',
    errorMessage: 'Error updating scheduler sign permissions',
    axios: 'schedulerAxios',
  });

  const updateSchedulerSignPermissions = (data: IUpdateSchedulerSignPermissionsRequest) => {
    setUpdateSchedulerPermissionsApiConfig({
      url: `users/${data.userId}/signs/permissions`,
      method: 'put',
      data: {
        signIds: data.signIds,
      },
    });
  };

  useEffect(() => {
    if (updateSchedulerSignPermissionsResponse) setUpdatedPermissions({});
  }, [updateSchedulerSignPermissionsResponse]);

  return {
    updateSchedulerSignPermissions,
    updatedPermissions,
    updateSchedulerSignPermissionsIsLoading,
  };
};

export default useUpdateSchedulerSignPermissions;
