import { useEffect, useState } from 'react';

import { useToggle } from '@/hooks';
import { IScheduleSignResponse } from '@/types/sign';
import { useDeleteSchedulerSign } from '@/hooks/sign';

const useSchedulerSignDeleteModal = () => {
  const { show: showModal, hide: hideSchedulerSignDeleteModal, isVisible: isSchedulerSignDeleteModalVisible } = useToggle();
  const [schedulerSignToDelete, setSchedulerSignToDelete] = useState<IScheduleSignResponse | null>(null);

  const {
    deleteSchedulerSign,
    deletedSchedulerSign,
    deleteSchedulerSignIsLoading,
  } = useDeleteSchedulerSign();

  const showSchedulerSignDeleteModal = (sign: IScheduleSignResponse) => {
    setSchedulerSignToDelete(sign);
    return showModal();
  };

  const submitSchedulerSignDelete = () => {
    if (!schedulerSignToDelete) return;
    deleteSchedulerSign(schedulerSignToDelete.id);
  };

  useEffect(() => {
    if (!deletedSchedulerSign) return;
    hideSchedulerSignDeleteModal();
  }, [deletedSchedulerSign]);

  return {
    showSchedulerSignDeleteModal,
    hideSchedulerSignDeleteModal,
    isSchedulerSignDeleteModalVisible,
    submitSchedulerSignDelete,
    deletedSchedulerSign,
    deleteSchedulerSignIsLoading,
    schedulerSignToDelete,
  };
};

export default useSchedulerSignDeleteModal;
