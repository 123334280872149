import React from 'react';
import { Formik, FormikProps } from 'formik';
import Modal from 'react-bootstrap/esm/Modal';
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { ICompanyResponse, IProjectContentCompanyResponse, IProjectContentCreditsUpdateRequest } from '@/types/company';

interface CompanyEditCreditsModalProps {
  isVisible: boolean,
  onHide: () => void,
  company: ICompanyResponse,
  projectContentCompany: IProjectContentCompanyResponse,
  onSubmit: (company: IProjectContentCreditsUpdateRequest) => void,
  isSubmitting?: boolean,
}

interface IProjectContentEditCreditsRequestForm {
  downloadCredits: number,
  requestCredits: number,
}

const CompanyEditCreditsModal = ({
  isVisible,
  onHide,
  company,
  projectContentCompany,
  onSubmit,
  isSubmitting,
}: CompanyEditCreditsModalProps) => {
  const schema = Yup.object().shape({
    downloadCredits: Yup.number()
      .positive('Download credits must be a positive value')
      .integer('Download credits must be an integer')
      .required('Download credits is required')
      .min(0, 'Request credits must be 0 or greater'),
    requestCredits: Yup.number()
      .positive('Request credits must be a positive value')
      .integer('Request credits must be an integer')
      .required('Request credits is required')
      .min(0, 'Request credits must be 0 or greater'),
  });

  const initialValues: IProjectContentEditCreditsRequestForm = {
    downloadCredits: projectContentCompany.downloadCredits,
    requestCredits: projectContentCompany.requestCredits,
  };

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        if (isSubmitting) return;

        onHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Credits for {company.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            onSubmit({
              id: projectContentCompany.id,
              downloadCredits: values.downloadCredits,
              requestCredits: values.requestCredits,
            });
          }}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            touched,
            errors,
            handleBlur,
          }: FormikProps<IProjectContentEditCreditsRequestForm>) => (
            <Form
              id="edit-company-credits-form"
              onSubmit={handleSubmit}
            >
              <Form.Group className="mb-3">
                <Form.Label>Download Credits</Form.Label>
                <Form.Control
                  type="number"
                  name="downloadCredits"
                  value={values.downloadCredits}
                  onChange={(e) => setFieldValue('downloadCredits', e.target.value)}
                  isValid={touched.downloadCredits && !errors.downloadCredits}
                  isInvalid={!!errors.downloadCredits && touched.downloadCredits}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.downloadCredits && touched.downloadCredits
                    ? errors.downloadCredits
                    : 'Invalid Download Credits'}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Request Credits</Form.Label>
                <Form.Control
                  type="number"
                  name="requestCredits"
                  value={values.requestCredits}
                  onChange={(e) => setFieldValue('requestCredits', e.target.value)}
                  isValid={touched.requestCredits && !errors.requestCredits}
                  isInvalid={!!errors.requestCredits && touched.requestCredits}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.requestCredits && touched.requestCredits
                    ? errors.requestCredits
                    : 'Invalid Request Credits'}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          form="edit-company-credits-form"
          type="submit"
          variant="primary"
          disabled={isSubmitting}
        >
          {isSubmitting
            ? <FontAwesomeIcon icon={faCircleNotch} spin />
            : 'Save'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompanyEditCreditsModal;
