import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/esm/Button';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';

import { ICompanyResponse } from '@/types/company';

interface CompanyListItemProps {
  company: ICompanyResponse,
  onEmulate: (company: ICompanyResponse) => void,
  onEdit: (company: ICompanyResponse) => void,
  onDelete: (company: ICompanyResponse) => void,
}

const companyListItem = ({
  company,
  onEmulate,
  onEdit,
  onDelete,
}: CompanyListItemProps) => {
  const routerNavigate = useNavigate();

  const hasUsers = Boolean(company.users && company.users.length > 0);

  return (
    <div className="border-bottom d-flex align-items-center">
      <div
        className="py-3 px-2 flex-grow-1 cursor-pointer text-truncate"
        onClick={() => routerNavigate(`/admin/companies/${company.id}`)}
      >
        <span className="fw-bold text-truncate">
          { company.name }
        </span><br />
        <span className="text-muted small">
          Team Members ({ company.users ? company.users.length : 0 })
        </span>
      </div>

      <div className="d-flex">
        {hasUsers && (
          <OverlayTrigger
            overlay={<Tooltip>Emulate Company</Tooltip>}
          >
            <Button
              className="text-primary me-2 position-relative"
              variant="link"
              onClick={() => onEmulate(company)}
            >
              <FontAwesomeIcon icon={faArrowRightFromBracket} />
            </Button>
          </OverlayTrigger>
        )}
        <Dropdown>
          <Dropdown.Toggle
            className="text-dark"
            as={Button}
            variant="link"
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </Dropdown.Toggle>

          <Dropdown.Menu align="end">
            <Dropdown.Item onClick={() => onEdit(company)}>Edit Company</Dropdown.Item>
            <Dropdown.Item onClick={() => onDelete(company)}>Remove Company</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default companyListItem;
