import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';
import { Formik, FormikProps } from 'formik';

import { IUserResponse } from '@/types/user';
import Loader from '@/components/Loader';
import { IScheduleSignResponse } from '@/types/sign';
import { useReadSchedulerSignPermissions } from '@/hooks/sign';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_USER } from '@/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

interface SchedulerPermissionsModalProps {
  isVisible: boolean,
  onHide: () => void,
  user: IUserResponse
  isSubmitting?: boolean,
  schedulerSigns?: IScheduleSignResponse[],
  onSubmit: (user: IUserResponse, signIds: number[]) => void,
}

const SchedulerPermissionsModal = ({
  isVisible,
  onHide,
  user,
  isSubmitting = false,
  schedulerSigns = [],
  onSubmit,
}: SchedulerPermissionsModalProps) => {
  const {
    signPermissions,
    readSchedulerSignPermissions,
    readSchedulerSignPermissionsIsLoading,
  } = useReadSchedulerSignPermissions();

  useEffect(() => {
    if (isVisible === true && user) {
      readSchedulerSignPermissions(user.id);
    }
  }, [isVisible]);

  const initialValues: {
    schedulerSignPermissions: number[]
  } = {
    schedulerSignPermissions: signPermissions
      ? signPermissions.map((item) => item.signId)
      : [],
  };

  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Scheduler Sign Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(readSchedulerSignPermissionsIsLoading)
          ? (<Loader />)
          : (
            <Formik
              initialValues={initialValues}
              onSubmit={(values) =>
                onSubmit(user, values.schedulerSignPermissions)
              }
              enableReinitialize
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
              }: FormikProps<{
                schedulerSignPermissions: number[]
              }>) => {
                return (
                  <Form
                    id="scheduler-sign-permissions-form"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <p>
                      {(user.role === ROLE_ADMIN || user.role === ROLE_SUPER_ADMIN)
                        ? <>Admins have full access to all signs.</>
                        : <>Select the Scheduler signs the user will have access to.</>
                      }
                    </p>
                    {(schedulerSigns.length === 0)
                      ? (<>
                        <hr />
                        <div>
                          No Scheduler signs found
                        </div>
                      </>)
                      : (<>
                        {(user.role === ROLE_USER) && (<>
                          <div className="mb-2">
                            <Button
                              size="sm"
                              variant="outline-primary"
                              onClick={() => {
                                if (values.schedulerSignPermissions.length === schedulerSigns.length) {
                                  // deselect all
                                  setFieldValue('schedulerSignPermissions', []);
                                } else {
                                  // select all
                                  setFieldValue('schedulerSignPermissions', schedulerSigns.map((sign) => sign.id));
                                }
                              }}
                            >
                              {(values.schedulerSignPermissions.length === schedulerSigns.length)
                                ? (<>Deselect All</>)
                                : (<>Select All</>)
                              }
                            </Button>
                          </div>
                          <ListGroup as="div">
                            {(schedulerSigns.length > 0) && (
                              schedulerSigns.map((sign) => (
                                <ListGroupItem
                                  key={sign.id}
                                  as="label"
                                  className="d-flex cursor-pointer"
                                >
                                  <Form.Check
                                    className="me-2"
                                    value={sign.id}
                                    checked={values.schedulerSignPermissions.some((signId) => sign.id === signId)}
                                    onChange={(changeEvent) => {
                                      const checked = changeEvent.target.checked;

                                      const checkedList = checked
                                        ? [...values.schedulerSignPermissions, sign.id]
                                        : values.schedulerSignPermissions.filter((id) => id !== sign.id);

                                      setFieldValue('schedulerSignPermissions', checkedList);
                                    }}
                                  />
                                  <div>
                                    <span>
                                      {sign.name}
                                    </span><br />
                                    <span className="text-muted small">
                                      { sign.city }
                                      {(sign.city) && (<>&nbsp;,&nbsp;</>)}
                                      { sign.state }
                                      {(sign.city || sign.state) && (<>&nbsp;|&nbsp;</>)}
                                      { sign.width } x { sign.height }
                                    </span>
                                  </div>
                                </ListGroupItem>
                              ))
                            )}
                          </ListGroup>
                        </>)}
                      </>)
                    }
                  </Form>);
              }}
            </Formik>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
          disabled={isSubmitting}
        >
          Close
        </Button>
        <Button
          form="scheduler-sign-permissions-form"
          type="submit"
          variant="primary"
          disabled={isSubmitting}
        >
          {isSubmitting
            ? <FontAwesomeIcon icon={faCircleNotch} spin />
            : <>Save</>
          }
        </Button>
      </Modal.Footer>

    </Modal>
  );
};

export default SchedulerPermissionsModal;
