import { IUserResponse } from '@/types/user';
import { ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_USER } from '@/constants';

export const canMyUserEditRole = (myUser: IUserResponse, targetUser: IUserResponse): {
  allowed: boolean,
  notAllowedReason: string | null,
} => {
  switch (myUser.role) {
    case ROLE_SUPER_ADMIN:
      return {
        allowed: true,
        notAllowedReason: null,
      };
    case ROLE_ADMIN:
      // user can edit only if target user's role is not superAdmin
      return {
        allowed: targetUser.role !== ROLE_SUPER_ADMIN,
        notAllowedReason: targetUser.role !== ROLE_SUPER_ADMIN
          ? null
          : 'Admin role cannot edit super admin user',
      };
    case ROLE_USER:
      return {
        allowed: false,
        notAllowedReason: 'User role cannnot edit roles',
      };
    default:
      return {
        allowed: false,
        notAllowedReason: null,
      };
  }
};

export const canMyUserDeleteUser = (myUser: IUserResponse, targetUser: IUserResponse): {
  allowed: boolean,
  notAllowedReason: string | null,
} => {
  switch (myUser.role) {
    case ROLE_SUPER_ADMIN:
      // can only delete non super admins
      return {
        allowed: targetUser.role !== ROLE_SUPER_ADMIN,
        notAllowedReason: targetUser.role !== ROLE_SUPER_ADMIN
          ? null
          : 'Super admin role cannot delete another super admin',
      };
    case ROLE_ADMIN:
      // user can delete only if target user's role is not superAdmin
      return {
        allowed: targetUser.role !== ROLE_SUPER_ADMIN,
        notAllowedReason: targetUser.role !== ROLE_SUPER_ADMIN
          ? null
          : 'Admin role cannot delete super admin user',
      };
    case ROLE_USER:
      return {
        allowed: false,
        notAllowedReason: 'User role cannot delete users',
      };
    default:
      return {
        allowed: false,
        notAllowedReason: null,
      };
  }
};
