import React from 'react';

import Loader from '@/components/Loader';
import { useMyUser } from '@/context/myUser';
import CompanyListPage from '@/features/Admin/components/CompanyListPage';
import { ROLE_SUPER_ADMIN, ROLE_ADMIN } from '@/constants';
import UnauthorizedPage from '@/components/UnauthorizedPage';

import CompanyDetailsFromUser from './CompanyDetailsFromUser';

const AdminCatchAllPage = () => {
  const { myUser, myUserIsLoading } = useMyUser();

  if (myUserIsLoading) {
    return <Loader size="2x" />;
  }

  if (myUser?.role === ROLE_SUPER_ADMIN) {
    return (
      <CompanyListPage />
    );
  }

  if (myUser?.role === ROLE_ADMIN) {
    return (
      <CompanyDetailsFromUser />
    );
  }

  return <UnauthorizedPage />;
};

export default AdminCatchAllPage;
