import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useCallApi } from '@/hooks';
import { IReadSchedulerSignPermissionsResponse } from '@/types/sign';

const useReadSchedulerSignPermissions = () => {
  const [signPermissions, setSignPermissions] = useState<IReadSchedulerSignPermissionsResponse | null>(null);
  const [readSchedulerPermissionsApiConfig, setReadSchedulerPermissionsApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readSchedulerSignPermissionsResponse,
    loading: readSchedulerSignPermissionsIsLoading,
  } = useCallApi(readSchedulerPermissionsApiConfig, {
    errorMessage: 'Error reading scheduler sign permissions',
    axios: 'schedulerAxios',
  });

  const readSchedulerSignPermissions = (userId: string) => {
    setReadSchedulerPermissionsApiConfig({
      url: `users/${userId}/signs/permissions`,
      method: 'get',
    });
  };

  useEffect(() => {
    if (readSchedulerSignPermissionsResponse) setSignPermissions(readSchedulerSignPermissionsResponse.data);
  }, [readSchedulerSignPermissionsResponse]);

  return {
    readSchedulerSignPermissions,
    signPermissions,
    readSchedulerSignPermissionsIsLoading,
  };
};

export default useReadSchedulerSignPermissions;
