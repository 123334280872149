import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { DASHBOARD_EXTERNAL_NAV_ITEMS, DASHBOARD_NAV_ITEMS } from '@/layout/constants';
import IfElseWrapper from '@/components/wrappers/IfElseWrapper';
import CustomIcon from '@/components/CustomIcon';
import { NavItem } from '@/layout/types';
import { useMyUser } from '@/context/myUser';

import './SideNav.scss';

const sideNav = () => {
  const { myUser } = useMyUser();
  const [filteredDashboardItems, setFilteredDashboardItems] = useState<NavItem[]>([]);

  useEffect(() => {
    if (!myUser) {
      setFilteredDashboardItems([]);
      return;
    }

    const filteredItems = DASHBOARD_NAV_ITEMS.filter((item) => {
      if (!item.allowRule) return true;

      return item.allowRule(myUser);
    });

    setFilteredDashboardItems(filteredItems);
  }, [myUser]);

  return (
    <div
      className="side-nav-container"
    >
      <div className="p-4 bg-white mb-2 shadow-sm">
        {DASHBOARD_EXTERNAL_NAV_ITEMS.map((item) => (
          <IfElseWrapper
            key={item.name}
            condition={!!item.isExternal}
            wrapperIf={(children) => <a href={item.url}>{children}</a>}
            wrapperElse={(children) => <NavLink to={item.url}>{children}</NavLink>}
          >
            <div className="side-nav-list-item d-flex align-items-center justify-content-between">
              <div>
                {(item.customIcon) && (
                  <CustomIcon
                    className={item.className}
                    style={{
                      height: '18px',
                    }}
                    type={item.customIcon}
                  />
                )}
                { item.name }
              </div>
              <FontAwesomeIcon
                className="side-nav-caret"
                icon={faCaretRight}
              />
            </div>
          </IfElseWrapper>
        ))}
      </div>
      <div className="p-4 bg-white shadow-sm flex-grow-1">
        {filteredDashboardItems.map((item) => (
          <NavLink
            key={item.name}
            to={item.url}
          >
            {({ isActive }) => (
              <div
                className={classNames({
                  'side-nav-list-item': true,
                  'active': isActive,
                })}
              >
                {(item.customIcon) && (
                  <CustomIcon
                    className="me-3"
                    style={{
                      width: 22,
                      height: 'auto',
                    }}
                    type={item.customIcon}
                  />
                )}
                {(!item.customIcon && item.icon) && (
                  <div
                    className="d-inline-flex align-items-center me-3"
                    style={{
                      width: 22,
                      height: 'auto',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      style={{
                        width: '100%',
                        fontSize: 18,
                      }}
                    />
                  </div>
                )}
                { item.name }
              </div>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default sideNav;
