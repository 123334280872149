import { useEffect, useState } from 'react';

import { useToggle } from '@/hooks';
import { useSendPasswordResetEmail } from '@/hooks/user';
import { IUserResponse } from '@/types/user';

const useSendPasswordResetEmailModal = () => {
  const { show: showModal, hide: hidePasswordResetEmailModal, isVisible: isPasswordResetEmailModalVisible } = useToggle();
  const [userToSendPasswordResetEmail, setUserToSendPasswordResetEmail] = useState<IUserResponse | null>(null);

  const {
    sendPasswordResetEmail,
    sendPasswordResetEmailIsLoading,
    sendPasswordResetEmailResponse,
  } = useSendPasswordResetEmail();

  const showPasswordResetEmailModal = (user: IUserResponse) => {
    setUserToSendPasswordResetEmail(user);
    showModal();
  };

  const submitSendPasswordResetEmail = () => {
    if (!userToSendPasswordResetEmail) return;
    sendPasswordResetEmail(userToSendPasswordResetEmail.id);
  };

  useEffect(() => {
    if (sendPasswordResetEmailResponse) hidePasswordResetEmailModal();
  }, [sendPasswordResetEmailResponse]);

  return {
    showPasswordResetEmailModal,
    hidePasswordResetEmailModal,
    isPasswordResetEmailModalVisible,
    submitSendPasswordResetEmail,
    sendPasswordResetEmailIsLoading,
    userToSendPasswordResetEmail,
  };
};

export default useSendPasswordResetEmailModal;
