import { useEffect } from 'react';

import { useToggle } from '@/hooks';
import { useUpdateOneCompany } from '@/hooks/company';
import { ICompanyUpdateRequest } from '@/types/company';

const useCompanyEditModal = () => {
  const { show: showCompanyEditModal, hide: hideCompanyEditModal, isVisible: isCompanyEditModalVisible } = useToggle();

  const {
    updateOneCompany,
    updatedCompany,
    updateOneCompanyIsLoading,
  } = useUpdateOneCompany();

  const submitCompanyEdit = (company: ICompanyUpdateRequest) => {
    updateOneCompany(company);
  };

  useEffect(() => {
    if (!updatedCompany) return;

    hideCompanyEditModal();
  }, [updatedCompany]);

  return {
    showCompanyEditModal,
    hideCompanyEditModal,
    isCompanyEditModalVisible,
    updatedCompany,
    updateOneCompanyIsLoading,
    submitCompanyEdit,
  };
};

export default useCompanyEditModal;
