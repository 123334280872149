import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

import { useCallApi } from '@/hooks';
import { IUserResponse, IUserCreateRequest } from '@/types/user';
import { TOAST_TYPE_ERROR } from '@/constants';

const useCreateUser = () => {
  const [createdUser, setCreatedUser] = useState<IUserResponse | null>(null);
  const [postUsersApiConfig, setPostUsersApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: createUsersResponse,
    loading: createUserIsLoading,
    error: createUserError,
  } = useCallApi(postUsersApiConfig, {
    successMessage: 'User Created',
    ignoreError: true,
  });

  const createUser = (newUserData: IUserCreateRequest) => {
    setPostUsersApiConfig({
      url: '/users',
      method: 'post',
      data: newUserData,
    });
  };

  useEffect(() => {
    setCreatedUser(createUsersResponse?.data);
  }, [createUsersResponse]);

  useEffect(() => {
    if (!createUserError) return;

    let message = 'Unable to create user';

    if (
      createUserError.errors
      && createUserError.errors.length > 0
      && createUserError.errors.some((item) => item.field === 'email' && item.rule === 'unique')
    ) {
      message = 'Email is already in use.';
    }

    toast(message, { type: TOAST_TYPE_ERROR });
  }, [createUserError]);

  return {
    createUser,
    createdUser,
    createUserIsLoading,
  };
};

export default useCreateUser;
