import React from 'react';
import { Formik, FormikProps } from 'formik';
import Modal from 'react-bootstrap/esm/Modal';
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

import { IUserResponse, IUserUpdateRequest, IUserEditRequestForm } from '@/types/user';
import { ASSIGNABLE_USER_ROLES } from '@/features/Admin/constants';
import { ROLE_SUPER_ADMIN, ROLE_USER } from '@/constants';

interface UserEditModalProps {
  isVisible: boolean,
  onHide: () => void,
  user: IUserResponse,
  onSubmit: (user: IUserUpdateRequest) => void,
  isSubmitting?: boolean,
}

const UserEditModal = ({
  isVisible,
  onHide,
  user,
  onSubmit,
  isSubmitting = false,
}: UserEditModalProps) => {
  // if user is already super-admin, add super-admin option
  // to dropdown options so user can save role as is
  const userRoleOptions = user.role === ROLE_SUPER_ADMIN
    ? [ROLE_SUPER_ADMIN, ...ASSIGNABLE_USER_ROLES]
    : [...ASSIGNABLE_USER_ROLES];

  const schema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
  });

  const initialValues: IUserEditRequestForm = {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email,
    role: user.role || ROLE_USER,
  };

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        if (isSubmitting) return;

        onHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit {user.fullName || user.email}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values: IUserEditRequestForm) => {
            onSubmit({
              id: user.id,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              role: values.role,
            });
          }}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            touched,
            errors,
            handleBlur,
          }: FormikProps<IUserEditRequestForm>) => {
            return (
              <Form
                id="edit-user-form"
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={(e) => setFieldValue('firstName', e.target.value)}
                        isValid={touched.firstName && !errors.firstName}
                        isInvalid={!!errors.firstName && touched.firstName}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName && touched.firstName
                          ? errors.firstName
                          : 'Invalid First Name'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={(e) => setFieldValue('lastName', e.target.value)}
                        isValid={touched.lastName && !errors.lastName}
                        isInvalid={!!errors.lastName && touched.lastName}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName && touched.lastName
                          ? errors.lastName
                          : 'Invalid Last Name'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    isValid={touched.email && !errors.email}
                    isInvalid={!!errors.email && touched.email}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email && touched.email
                      ? errors.email
                      : 'Invalid Email'}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    name="role"
                    defaultValue={values.role}
                    onChange={(e) => setFieldValue('role', e.target.value)}
                  >
                    {userRoleOptions.map((role) => {
                      return <option key={role} value={role}>{role}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          form="edit-user-form"
          type="submit"
          variant="primary"
          disabled={isSubmitting}
        >
          {isSubmitting
            ? <FontAwesomeIcon icon={faCircleNotch} spin />
            : 'Save'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserEditModal;
