import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useCallApi } from '@/hooks';
import { IProjectContentCompanyResponse } from '@/types/company';

const NOT_FOUND_STATUS_CODE = 404;

const useReadOneProjectContentCompany = () => {
  const [projectContentCompany, setProjectContentCompany] = useState<IProjectContentCompanyResponse | null>(null);
  const [getProjectContentCompanyApiConfig, setGetProjectContentCompanyApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readOnePrjoectContentCompanyResponse,
    loading: readOneProjectContentCompanyIsLoading,
  } = useCallApi(getProjectContentCompanyApiConfig, {
    errorMessage: 'Unable to load project content company data',
    axios: 'projectContentAxios',
    ignoreStatusCodes: [NOT_FOUND_STATUS_CODE],
  });

  const readOneProjectContentCompany = (companyId: string) => {
    setGetProjectContentCompanyApiConfig({
      url: `/company/${companyId}`,
      method: 'get',
    });
  };

  useEffect(() => {
    if (!readOnePrjoectContentCompanyResponse) return;

    setProjectContentCompany(readOnePrjoectContentCompanyResponse.data);
  }, [readOnePrjoectContentCompanyResponse]);

  return {
    readOneProjectContentCompany,
    projectContentCompany,
    readOneProjectContentCompanyIsLoading,
  };
};

export default useReadOneProjectContentCompany;
