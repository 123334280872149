import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface PaginationProps {
  total: number,
  page: number,
  perPage: number,
  onPage: (page: number) => void,
}

const Pagination = ({
  total,
  page,
  perPage,
  onPage,
}: PaginationProps) => {
  const pageCount = Math.ceil(total / perPage);
  const isPreviousDisabled = page === 1;
  const isNextDisabled = page === pageCount;

  const startNumber = ((page - 1) * perPage) + 1;
  const endNumber = page * perPage >= total
    ? total
    : page * perPage;

  return (
    <div>
      {startNumber.toLocaleString()} - {endNumber.toLocaleString()} / {total.toLocaleString()}
      <span
        className={classNames({
          'ms-2': true,
          'cursor-not-allowed': isPreviousDisabled,
        })}
      >
        <Button
          variant="light"
          disabled={isPreviousDisabled}
          onClick={() => onPage(page - 1)}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
      </span>
      <span
        className={classNames({
          'ms-2': true,
          'cursor-not-allowed': isNextDisabled,
        })}
      >
        <Button
          variant="light"
          disabled={isNextDisabled}
          onClick={() => onPage(page + 1)}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </span>
    </div>
  );
};

export default Pagination;
