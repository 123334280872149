import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { IProjectContentSignResponse } from '@/types/sign';

interface ProjectContentSignListItemProps {
  sign: IProjectContentSignResponse,
  canManageSigns?: boolean,
  onEdit?: (sign: IProjectContentSignResponse) => void,
  onDelete?: (sign: IProjectContentSignResponse) => void,
}

const ProjectContentSignListItem = ({
  sign,
  canManageSigns = true,
  onEdit,
  onDelete,
}: ProjectContentSignListItemProps) => {
  return (
    <div className="border-bottom d-flex align-items-center">
      <div className="py-3 px-2 flex-grow-1">
        <span className="fw-bold">
          { sign.name }
        </span><br />
        <span className="text-muted small">
          { sign.city } {(sign.city && sign.stateProvince) && (',')} { sign.stateProvince }
          {(sign.city || sign.stateProvince) && (<>&nbsp;|&nbsp;</>)}
          { sign.width || '-'} x { sign.height || '-' }
        </span>
      </div>

      {((onEdit || onDelete) && canManageSigns) && (
        <div className="d-flex">
          <Dropdown>
            <Dropdown.Toggle
              className="text-dark"
              as={Button}
              variant="link"
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </Dropdown.Toggle>

            <Dropdown.Menu align="end">
              {(onEdit) && (<Dropdown.Item
                onClick={() => onEdit(sign)}
              >
                Edit Sign
              </Dropdown.Item>)}
              {(onDelete) && (<Dropdown.Item
                onClick={() => onDelete(sign)}
              >
                Delete Sign
              </Dropdown.Item>)}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default ProjectContentSignListItem;
