import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/esm/Button';
import Offcanvas from 'react-bootstrap/esm/Offcanvas';

import { useToggle } from '@/hooks';

export type FloatingActionButtonOptions = {
  icon: IconProp,
  text: string,
  onClick: () => void,
  disabled?: boolean,
}[];

interface FloatingActionButtonProps {
  icon?: IconProp,
  disabled?: boolean,
  options: FloatingActionButtonOptions,
}

const FloatingActionButton = ({
  icon = faPlus,
  disabled = false,
  options = [],
}: FloatingActionButtonProps) => {
  const { show, hide, isVisible } = useToggle();

  return (
    <>
      <Button
        className="floating-action-button fixed-bottom-right"
        variant="secondary"
        onClick={() => {
          // if only 1 option, perform onClick for that option
          if (options.length === 1) {
            options[0].onClick();
          } else {
            // else show multiple options
            show();
          }
        }}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={icon} />
      </Button>
      <Offcanvas
        show={isVisible}
        onHide={hide}
        placement="end"
        className="offcanvas-bottom-right"
      >
        <Offcanvas.Body
          className="p-0"
        >
          {options.map((item) => (
            <div
              className={classNames('d-flex align-items-center justify-content-end', {
                'cursor-pointer': !item.disabled,
              })}
              key={item.text}
              onClick={() => {
                item.onClick();
                hide();
              }}
            >
              <div className="py-2 d-flex align-items-center">
                <div className="px-2 py-1 bg-white shadow-sm rounded-1 fw-bold">
                  { item.text }
                </div>
                <Button
                  className="offcanvas-bottom-right-button ms-3"
                  variant="primary"
                  onClick={() => {
                    item.onClick();
                    hide();
                  }}
                  disabled={item.disabled}
                >
                  <FontAwesomeIcon icon={item.icon} />
                </Button>
              </div>
            </div>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FloatingActionButton;
