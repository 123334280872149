import { useState } from 'react';

import { useToggle } from '@/hooks';
import { IUserResponse } from '@/types/user';
import { useUpdateSchedulerSignPermissions } from '@/hooks/sign';

const useSchedulerPermissionsModal = () => {
  const { show: showModal, hide: hideSchedulerPermissionsModal, isVisible: isSchedulerPermissionsModalVisible } = useToggle();
  const [userToEditPermissions, setUserToEditPermissions] = useState<IUserResponse | null>(null);

  const {
    updateSchedulerSignPermissions,
    updateSchedulerSignPermissionsIsLoading,
    updatedPermissions,
  } = useUpdateSchedulerSignPermissions();

  const showSchedulerPermissionsModal = (user: IUserResponse) => {
    setUserToEditPermissions(user);
    showModal();
  };

  const submitSchedulerPermissions = (user: IUserResponse, signIds: number[]) => {
    updateSchedulerSignPermissions({
      userId: user.id,
      signIds,
    });
  };

  return {
    showSchedulerPermissionsModal,
    hideSchedulerPermissionsModal,
    isSchedulerPermissionsModalVisible,
    submitSchedulerPermissions,
    userToEditPermissions,
    updateSchedulerSignPermissionsIsLoading,
    updatedPermissions,
  };
};

export default useSchedulerPermissionsModal;
