import { useToggle } from '@/hooks';
import { useSendInviteEmail } from '@/hooks/user';
import { IUserResponse } from '@/types/user';
import { useEffect, useState } from 'react';

const useSendInviteEmailModal = () => {
  const { show: showModal, hide: hideSendInviteEmailModal, isVisible: isSendInviteEmailModalVisible } = useToggle();
  const [userToSendInviteEmail, setUserToSendInviteEmail] = useState<IUserResponse | null>(null);

  const {
    sendInviteEmail,
    sendInviteEmailIsLoading,
    sendInviteEmailResponse,
  } = useSendInviteEmail();

  const showSendInviteEmailModal = (user: IUserResponse) => {
    setUserToSendInviteEmail(user);
    showModal();
  };

  const submitSendInviteEmail = () => {
    if (!userToSendInviteEmail) return;
    sendInviteEmail(userToSendInviteEmail.id);
  };

  useEffect(() => {
    if (sendInviteEmailResponse) hideSendInviteEmailModal();
  }, [sendInviteEmailResponse]);

  return {
    showSendInviteEmailModal,
    hideSendInviteEmailModal,
    isSendInviteEmailModalVisible,
    submitSendInviteEmail,
    userToSendInviteEmail,
    sendInviteEmailIsLoading,
  };
};

export default useSendInviteEmailModal;
