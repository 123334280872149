import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { IScheduleSignResponse } from '@/types/sign';

interface SchedulerSignListItemProps {
  sign: IScheduleSignResponse,
  onEdit: (sign: IScheduleSignResponse) => void,
  onDelete: (sign: IScheduleSignResponse) => void,
  canManageSigns?: boolean,
  onClickSign?: (user: IScheduleSignResponse) => void,
}

const SchedulerSignListItem = ({
  sign,
  onEdit,
  onDelete,
  canManageSigns = true,
  onClickSign,
}: SchedulerSignListItemProps) => {
  return (
    <div className="border-bottom d-flex align-items-center">
      <div className="py-3 px-2 flex-grow-1">
        <span
          className={classNames({
            'fw-bold': true,
            'cursor-pointer text-underline': !!onClickSign,
          })}
          onClick={() => onClickSign && onClickSign(sign)}
        >
          { sign.name }
        </span><br />
        <span className="text-muted small">
          { sign.city }
          {(sign.city) && (<>&nbsp;,&nbsp;</>)}
          { sign.state }
          {(sign.city || sign.state) && (<>&nbsp;|&nbsp;</>)}
          { sign.width } x { sign.height }
        </span>
      </div>

      {canManageSigns && (<div className="d-flex">
        <Dropdown>
          <Dropdown.Toggle
            className="text-dark"
            as={Button}
            variant="link"
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </Dropdown.Toggle>

          <Dropdown.Menu align="end">
            <Dropdown.Item
              onClick={() => onEdit(sign)}
            >
              Edit Sign
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => onDelete(sign)}
            >
              Delete Sign
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>)}
    </div>
  );
};

export default SchedulerSignListItem;
