import React from 'react';

import { ICompanyResponse } from '@/types/company';

import CompanyListItem from './CompanyListItem';

interface CompanyListViewProps {
  companies: ICompanyResponse[],
  onEmulate: (company: ICompanyResponse) => void,
  onEdit: (company: ICompanyResponse) => void,
  onDelete: (company: ICompanyResponse) => void,
}

const CompanyListView = ({
  companies,
  onEmulate,
  onEdit,
  onDelete,
}: CompanyListViewProps) => {
  return (
    <section>
      {(companies.length === 0) && (
        <div className="px-2 py-3">
          No Companies Found
        </div>
      )}
      {companies.map((company) => (
        <CompanyListItem
          key={company.id}
          company={company}
          onEmulate={onEmulate}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </section>
  );
};

export default CompanyListView;
