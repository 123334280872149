import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IUserResponse, IUserUpdateRequest } from '@/types/user';
import { useCallApi } from '@/hooks';

const useUpdateUser = () => {
  const [updatedUser, setUpdatedUser] = useState<IUserResponse[] | null>(null);
  const [putUserApiConfig, setPutUserApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateUserResponse,
    loading: updateUserIsLoading,
  } = useCallApi(putUserApiConfig, {
    successMessage: 'User Updated',
    errorMessage: 'Unable to update user',
  });

  const updateUser = (updatedUserData: IUserUpdateRequest) => {
    setPutUserApiConfig({
      url: `/users/${updatedUserData.id}`,
      method: 'put',
      data: updatedUserData,
    });
  };

  useEffect(() => {
    setUpdatedUser(updateUserResponse?.data);
  }, [updateUserResponse]);

  return {
    updateUser,
    updatedUser,
    updateUserIsLoading,
  };
};

export default useUpdateUser;

