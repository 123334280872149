import React, { useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';

import { IUserResponse } from '@/types/user';
import useReadPasswordResetLink from '@/hooks/user/useReadPasswordResetLink';
import Loader from '@/components/Loader';
import { copyTextToClipboard } from '@/utils';
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '@/constants';

interface PasswordResetLinkModalProps {
  isVisible: boolean,
  onHide: () => void,
  user: IUserResponse | null,
}

const PasswordResetLinkModal = ({
  isVisible,
  onHide,
  user,
}: PasswordResetLinkModalProps) => {
  const {
    readPasswordResetLink,
    passwordResetLink,
    readPasswordResetLinkIsLoading,
  } = useReadPasswordResetLink();

  useEffect(() => {
    if (isVisible === true && user) {
      readPasswordResetLink(user.id);
    }
  }, [isVisible]);

  const copyLinkToClipboard = useCallback(async () => {
    try {
      if (!passwordResetLink) {
        throw Error('password reset link not available');
      }

      await copyTextToClipboard(passwordResetLink);

      toast('Successfully copied link to clipboard', {
        type: TOAST_TYPE_SUCCESS,
      });
    } catch (error) {
      toast('Error copying link to clipboard.', {
        type: TOAST_TYPE_ERROR,
      });
    }
  }, [passwordResetLink]);

  if (!user) return <></>;

  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Password Reset</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(readPasswordResetLinkIsLoading) && (<Loader />)}
        {(passwordResetLink) && (<>
          <p>
            Below is a password reset link for <strong>{user.email}</strong>.
          </p>
          <p>
            This link is valid for 30 days.
          </p>
          <InputGroup>
            <Form.Control
              value={passwordResetLink}
              readOnly
            />
            <Button
              type="button"
              variant="outline-primary"
              onClick={copyLinkToClipboard}
            >
              <FontAwesomeIcon
                className="me-2"
                icon={faClipboard}
              />
              Copy
            </Button>
          </InputGroup>
        </>)}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
        >
          Close
        </Button>
      </Modal.Footer>

    </Modal>
  );
};

export default PasswordResetLinkModal;
