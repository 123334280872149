import React from 'react';

import { useMyUser } from '@/context/myUser';
import Loader from '@/components/Loader';

import CompanyDetailsPage from './CompanyDetailsPage';

const CompanyDetailsFromUser = () => {
  const { myUser } = useMyUser();

  if (!myUser) return <Loader />;

  return (<CompanyDetailsPage
    companyId={myUser.company.id}
  />);
};

export default CompanyDetailsFromUser;
