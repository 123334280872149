import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IndexApiParams } from '@/types/api';
import { IScheduleSignResponse } from '@/types/sign';
import { useCallApi } from '@/hooks';

const useReadSchedulerSigns = () => {
  const [schedulerSigns, setSchedulerSigns] = useState<IScheduleSignResponse[]>([]);
  const [getSchedulerSignListApiConfig, setGetSchedulerSignListApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readSchedulerSignsResponse,
    loading: readSchedulerSignsIsLoading,
  } = useCallApi(getSchedulerSignListApiConfig, {
    errorMessage: 'Error loading scheduler signs',
    axios: 'schedulerAxios',
  });

  const readSchedulerSigns = (params: IndexApiParams = {}) => {
    setGetSchedulerSignListApiConfig({
      url: '/signs',
      method: 'get',
      params,
    });
  };

  useEffect(() => {
    setSchedulerSigns(readSchedulerSignsResponse?.data || []);
  }, [readSchedulerSignsResponse]);

  return {
    readSchedulerSigns,
    schedulerSigns,
    readSchedulerSignsIsLoading,
  };
};

export default useReadSchedulerSigns;
