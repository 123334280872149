import React, { ComponentType } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

interface ProtectedRouteProps {
  component: ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (<div id="splash-screen">
      <img
        src="/pc_logo.png"
        alt="Project Content Logo"
      />
    </div>),
  });

  return <Component />;
};

export default ProtectedRoute;
