import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

import { STATUS_CODE_401, TOAST_TYPE_ERROR } from '@/constants';
import { useCallApi } from '@/hooks';

const useReadPasswordResetLink = () => {
  const [passwordResetLink, setPasswordResetLink] = useState<string | null>(null);
  const [getPasswordResetLinkApiConfig, setGetPasswordResetLinkApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readPasswordResetLinkResponse,
    error: readPasswordResetLinkResponseError,
    loading: readPasswordResetLinkIsLoading,
  } = useCallApi(getPasswordResetLinkApiConfig, {});

  const readPasswordResetLink = (userId: string) => {
    setPasswordResetLink(null);
    setGetPasswordResetLinkApiConfig({
      url: `/users/${userId}/password-reset-link`,
      method: 'get',
    });
  };

  useEffect(() => {
    if (readPasswordResetLinkResponseError && readPasswordResetLinkResponseError.status !== STATUS_CODE_401) {
      toast('Unable to load password reset link', { type: TOAST_TYPE_ERROR });
    }
  }, [readPasswordResetLinkResponseError]);

  useEffect(() => {
    setPasswordResetLink(readPasswordResetLinkResponse?.data?.passwordResetLink || null);
  }, [readPasswordResetLinkResponse]);

  return {
    readPasswordResetLink,
    passwordResetLink,
    readPasswordResetLinkIsLoading,
  };
};

export default useReadPasswordResetLink;

