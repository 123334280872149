import React from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/esm/Badge';
import ListGroup from 'react-bootstrap/esm/ListGroup';

import { ICompanyResponse, IProjectContentCompanyResponse } from '@/types/company';
import { DropdownItem } from 'react-bootstrap';

interface CompanyHeaderProps {
  company: ICompanyResponse,
  projectContentCompany: IProjectContentCompanyResponse | null,
  onEditCompany?: (company: ICompanyResponse) => void,
  onEditCredits?: (projectContentCompany: IProjectContentCompanyResponse) => void,
  onEditSubscriber?: (projectContentCompany: IProjectContentCompanyResponse) => void,
}

const companyHeader = ({
  company,
  projectContentCompany,
  onEditCompany,
  onEditCredits,
  onEditSubscriber,
}: CompanyHeaderProps) => {
  const projectContentSubscriberUserEmail = projectContentCompany
    && projectContentCompany.subscriberUser
    && company.users.some((user) => user.id === projectContentCompany.subscriberUser!.authId)
    ? company.users.find((user) => user.id === projectContentCompany.subscriberUser!.authId)!.email
    : 'N/A';

  return (
    <div className="px-4 py-4 bg-white shadow-sm">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center flex-grow-1 text-truncate">
          <div
            className="border border-primary rounded-circle d-inline-flex flex-shrink-0 justify-content-center align-items-center"
            style={{
              width: 40,
              height: 40,
            }}
          >
            <FontAwesomeIcon
              className="text-primary"
              icon={faBuilding}
            />
          </div>
          <span className="ms-2 d-inline-block h5 mb-0 text-truncate">{ company?.name || '-' }</span>
        </div>

        {(onEditCompany || onEditCredits || onEditSubscriber) && (
          <div className="d-flex">
            <Dropdown>
              <Dropdown.Toggle
                className="text-dark"
                as={Button}
                variant="link"
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </Dropdown.Toggle>

              <Dropdown.Menu align="end">
                {(onEditCompany) && (
                  <Dropdown.Item onClick={() => onEditCompany(company)}>Edit Company Details</Dropdown.Item>
                )}

                {(onEditCompany && (onEditCredits || onEditSubscriber)) && (<hr className="my-2" />)}

                {(onEditCredits) && (
                  <DropdownItem
                    onClick={() => projectContentCompany && onEditCredits(projectContentCompany)}
                  >
                    Edit Credits
                  </DropdownItem>
                )}
                {(onEditSubscriber) && (
                  <DropdownItem
                    onClick={() => projectContentCompany && onEditSubscriber(projectContentCompany)}
                  >
                    Edit Subscriber
                  </DropdownItem>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>

      {(projectContentCompany) && (<>
        <hr />

        <div>
          <ListGroup variant="flush">
            <ListGroup.Item className="d-flex flex-wrap">
              <div className="mb-2 me-4">
                <span className="fw-bold small">Plan</span><br />
                {projectContentCompany.plan ? projectContentCompany.plan.name : 'N/A'}
              </div>
              <div className="mb-2">
                <span className="fw-bold small">Subscriber</span> <br />
                {projectContentSubscriberUserEmail}
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <Badge
                className="me-2"
                bg="primary"
              >
                {projectContentCompany.downloadCredits}
              </Badge>
              Content Downloads
            </ListGroup.Item>
            <ListGroup.Item>
              <Badge
                className="me-2"
                bg="primary"
              >
                {projectContentCompany.requestCredits}
              </Badge>
              Custom Requests
            </ListGroup.Item>
          </ListGroup>
        </div>
      </>)}
    </div>
  );
};

export default companyHeader;
