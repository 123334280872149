import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useToggle } from '@/hooks';
import { IUserResponse, IUserUpdateRequest } from '@/types/user';
import { ROLE_ADMIN, ROLE_USER, TOAST_TYPE_ERROR } from '@/constants';
import { useUpdateUser } from '@/hooks/user';

const useUserEditModal = () => {
  const { show: showModal, hide: hideUserEditModal, isVisible: isUserEditModalVisible } = useToggle();
  const [userToEdit, setUserToEdit] = useState<IUserResponse | null>(null);

  const {
    updateUser,
    updateUserIsLoading,
    updatedUser,
  } = useUpdateUser();

  const showUserEditModal = (user: IUserResponse) => {
    setUserToEdit(user);
    showModal();
  };

  const submitUserEdit = (user: IUserUpdateRequest, users: IUserResponse[]) => {
    const userIsLastAdmin = user.role === ROLE_USER && users && users.filter((i) => i.role === ROLE_ADMIN).length < 2;
    if (userIsLastAdmin) {
      toast('There must be at least one admin user.', { type: TOAST_TYPE_ERROR });
      return;
    }

    updateUser(user);
  };

  useEffect(() => {
    if (!updatedUser) return;
    setUserToEdit(null);
    hideUserEditModal();
  }, [updatedUser]);

  return {
    showUserEditModal,
    hideUserEditModal,
    isUserEditModalVisible,
    submitUserEdit,
    updateUserIsLoading,
    updatedUser,
    userToEdit,
  };
};

export default useUserEditModal;
