/* eslint-disable complexity */
import React, { useEffect, useCallback } from 'react';
import { faRectangleList, faUser } from '@fortawesome/free-solid-svg-icons';
import _get from 'lodash/get';

import { useEmulateUser, useReadUsers } from '@/hooks/user';
import { useReadOneCompany, useReadOneProjectContentCompany } from '@/hooks/company';
import { useReadProjectContentSigns, useReadSchedulerSigns } from '@/hooks/sign';
import { useReadSignManufacturers } from '@/hooks/signManufacturer';
import Loader from '@/components/Loader';
import FloatingActionButton, { FloatingActionButtonOptions } from '@/components/FloatingActionButton';
import useMyUser from '@/context/myUser/useMyUser';
import ConfirmationModal from '@/components/ConfirmationModal';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '@/constants';
import {
  usePasswordResetLinkModal,
  useSendInviteEmailModal,
  useUserDeleteModal,
  useUserEditModal,
  useUserInviteModal,
  useSendPasswordResetEmailModal,
  useCompanyEditModal,
  useCompanyEditSubscriberModal,
} from '@/features/Admin/hooks';
import useSchedulerSignDeleteModal from '@/features/Admin/hooks/useSchedulerSignDeleteModal';
import useCompanyEditCreditsModal from '@/features/Admin/hooks/useCompanyEditCreditsModal';
import useSchedulerPermissionsModal from '@/features/Admin/hooks/useSchedulerPermissionsModal';
import { useToggle } from '@/hooks';
import { IProjectContentSignResponse } from '@/types/sign';

import UserInviteModal from './UserInviteModal';
import UserEditModal from './UserEditModal';
import CompanyHeader from './CompanyHeader';
import UserListView from './UserListView';
import PasswordResetLinkModal from './PasswordResetLinkModal';
import CompanyEditModal from './CompanyEditModal';
import SchedulerSignListView from './SchedulerSignListView';
import CompanyEditCreditsModal from './CompanyEditCreditsModal';
import CompanyEditSubscriberModal from './CompanyEditSubscriberModal';
import ProjectContentSignListView from './ProjectContentSignListView';
import SchedulerPermissionsModal from './SchedulerPermissionsModal';

// eslint-disable-next-line no-process-env
const REACT_APP_SCHEDULER_APP_URL = process.env.REACT_APP_SCHEDULER_APP_URL || '';
// eslint-disable-next-line no-process-env
const REACT_APP_PROJECT_CONTENT_APP_URL = process.env.REACT_APP_PROJECT_CONTENT_APP_URL || '';

interface CompanyDetailsPageProps {
  companyId: string,
}

const CompanyDetailsPage = ({
  companyId,
}: CompanyDetailsPageProps) => {
  const { myUser } = useMyUser();
  const { isVisible: isDeleteProjectContentSignModalVisible, show: showDeleteProjectContentSignModal, hide: hideDeleteProjectContentSignModal } = useToggle();

  const {
    readUsers,
    users,
    readUsersIsLoading,
  } = useReadUsers();

  const readUsersWithParams = useCallback(() => {
    readUsers({ companyId });
  }, [companyId, readUsers, users]);

  const {
    readOneCompany,
    company,
    readOneCompanyIsLoading,
  } = useReadOneCompany();

  const {
    readOneProjectContentCompany,
    projectContentCompany,
  } = useReadOneProjectContentCompany();

  const {
    readSchedulerSigns,
    schedulerSigns,
  } = useReadSchedulerSigns();

  const {
    readProjectContentSigns,
    projectContentSigns,
  } = useReadProjectContentSigns();

  const {
    readSignManufacturers,
    signManufacturers,
  } = useReadSignManufacturers();

  const {
    showUserEditModal,
    hideUserEditModal,
    isUserEditModalVisible,
    submitUserEdit,
    updatedUser,
    userToEdit,
    updateUserIsLoading,
  } = useUserEditModal();

  const {
    showUserDeleteModal,
    hideUserDeleteModal,
    isUserDeleteModalVisible,
    submitUserDelete,
    deletedUser,
    userToDelete,
    deleteOneUserIsLoading,
  } = useUserDeleteModal();

  const {
    showPasswordResetLinkModal,
    hidePasswordResetLinkModal,
    isPasswordReseLinktModalVisible,
    userToPasswordResetLink,
  } = usePasswordResetLinkModal();

  const {
    showUserInviteModal,
    hideUserInviteModal,
    isUserInviteModalVisible,
    submitUserInvite,
    createdUser,
    createUserIsLoading,
  } = useUserInviteModal();

  const {
    showSendInviteEmailModal,
    hideSendInviteEmailModal,
    isSendInviteEmailModalVisible,
    submitSendInviteEmail,
    userToSendInviteEmail,
    sendInviteEmailIsLoading,
  } = useSendInviteEmailModal();

  const {
    showPasswordResetEmailModal,
    hidePasswordResetEmailModal,
    submitSendPasswordResetEmail,
    isPasswordResetEmailModalVisible,
    sendPasswordResetEmailIsLoading,
    userToSendPasswordResetEmail,
  } = useSendPasswordResetEmailModal();

  const {
    emulateUser,
  } = useEmulateUser();

  const {
    showCompanyEditModal,
    hideCompanyEditModal,
    isCompanyEditModalVisible,
    updatedCompany,
    updateOneCompanyIsLoading,
    submitCompanyEdit,
  } = useCompanyEditModal();

  const {
    showCompanyEditCreditsModal,
    hideCompanyEditCreditsModal,
    isCompanyEditCreditsModalVisible,
    updateCreditsIsLoading,
    updatedCredits,
    submitCreditsUpdate,
  } = useCompanyEditCreditsModal();

  const {
    showCompanyEditSubscriberModal,
    hideCompanyEditSubscriberModal,
    isCompanyEditSubscriberModalVisible,
    updateProjectContentSubscriberIsLoading,
    updatedProjectContentSubscriber,
    submitSubscriberUpdate,
  } = useCompanyEditSubscriberModal();

  const {
    showSchedulerSignDeleteModal,
    hideSchedulerSignDeleteModal,
    isSchedulerSignDeleteModalVisible,
    schedulerSignToDelete,
    deletedSchedulerSign,
    deleteSchedulerSignIsLoading,
    submitSchedulerSignDelete,
  } = useSchedulerSignDeleteModal();

  const {
    showSchedulerPermissionsModal,
    hideSchedulerPermissionsModal,
    isSchedulerPermissionsModalVisible,
    userToEditPermissions,
    submitSchedulerPermissions,
    updateSchedulerSignPermissionsIsLoading,
    updatedPermissions,
  } = useSchedulerPermissionsModal();

  useEffect(() => {
    if (!companyId) return;
    readOneCompany(companyId);
    readOneProjectContentCompany(companyId);
    readSignManufacturers();

    readSchedulerSigns({ companyId });
    readProjectContentSigns(companyId);
    readUsersWithParams();
  }, [companyId]);

  useEffect(() => {
    if (!updatedUser) return;
    readUsersWithParams();
  }, [updatedUser]);

  useEffect(() => {
    if (!createdUser) return;
    readUsersWithParams();
  }, [createdUser]);

  useEffect(() => {
    if (!updatedCompany) return;

    readOneCompany(companyId);
  }, [updatedCompany]);

  useEffect(() => {
    if (!updatedCredits) return;

    readOneProjectContentCompany(companyId);
  }, [updatedCredits]);

  useEffect(() => {
    if (!updatedProjectContentSubscriber) return;

    readOneProjectContentCompany(companyId);
  }, [updatedProjectContentSubscriber]);

  useEffect(() => {
    if (!deletedSchedulerSign) return;
    readSchedulerSigns({ companyId });
  }, [deletedSchedulerSign]);

  useEffect(() => {
    if (!deletedUser) return;
    readUsersWithParams();
  }, [deletedUser]);

  useEffect(() => {
    if (!updatedPermissions) return;
    hideSchedulerPermissionsModal();
  }, [updatedPermissions]);

  if (!myUser) return <></>;

  const canManageSigns = myUser.role === ROLE_SUPER_ADMIN || _get(projectContentCompany, 'plan.canManageSigns');

  const floatingActionButtonOptions: FloatingActionButtonOptions = [
    {
      icon: faUser,
      text: 'User',
      onClick: showUserInviteModal,
    },
    {
      icon: faRectangleList,
      text: 'Scheduler Sign',
      onClick: () => {
        if (!canManageSigns) return;
        const encodedRedirectUrl = encodeURIComponent(window.location.href);
        window.location.href = `${REACT_APP_SCHEDULER_APP_URL}/signs/add?companyId=${companyId}&redirectUrl=${encodedRedirectUrl}`;
      },
      disabled: !canManageSigns,
    },
    {
      icon: faRectangleList,
      text: 'Project Content Sign',
      onClick: () => {
        if (!canManageSigns) return;
        const encodedRedirectUrl = encodeURIComponent(window.location.href);
        if (myUser.role === ROLE_SUPER_ADMIN) {
          window.location.href = `${REACT_APP_PROJECT_CONTENT_APP_URL}/admin/signs/${companyId}?addSign=1&redirectUrl=${encodedRedirectUrl}`;
        } else if (myUser.role === ROLE_ADMIN) {
          window.location.href = `${REACT_APP_PROJECT_CONTENT_APP_URL}/signs/?addSign=1&redirectUrl=${encodedRedirectUrl}`;
        }
      },
      disabled: !canManageSigns,
    },
  ];

  const onEmulateUser = myUser.role === ROLE_SUPER_ADMIN
    ? emulateUser
    : undefined;
  const onCreatePasswordResetLink = myUser.role === ROLE_SUPER_ADMIN
    ? showPasswordResetLinkModal
    : undefined;

  const onEditCompany = myUser.role === ROLE_SUPER_ADMIN
    ? showCompanyEditModal
    : undefined;
  const onEditCompanyCredits = myUser.role === ROLE_SUPER_ADMIN
    ? showCompanyEditCreditsModal
    : undefined;
  const onEditCompanySubscriber = myUser.role === ROLE_SUPER_ADMIN || myUser.role === ROLE_ADMIN
    ? showCompanyEditSubscriberModal
    : undefined;

  let onEditProjectContentSign: ((sign: IProjectContentSignResponse) => void) | undefined;
  let onDeleteProjectContentSign: ((sign: IProjectContentSignResponse) => void) | undefined;

  if (myUser.role === ROLE_SUPER_ADMIN) {
    onEditProjectContentSign = (sign: IProjectContentSignResponse) => {
      const encodedRedirectUrl = encodeURIComponent(window.location.href);
      window.location.href = `${REACT_APP_PROJECT_CONTENT_APP_URL}/admin/signs/${sign.companyId}?editSignId=${sign.id}&redirectUrl=${encodedRedirectUrl}`;
    };
    onDeleteProjectContentSign = (sign: IProjectContentSignResponse) => {
      const encodedRedirectUrl = encodeURIComponent(window.location.href);
      window.location.href = `${REACT_APP_PROJECT_CONTENT_APP_URL}/admin/signs/${sign.companyId}?deleteSignId=${sign.id}&redirectUrl=${encodedRedirectUrl}`;
    };
  } else if (myUser.role === ROLE_ADMIN) {
    onEditProjectContentSign = (sign: IProjectContentSignResponse) => {
      const encodedRedirectUrl = encodeURIComponent(window.location.href);
      window.location.href = `${REACT_APP_PROJECT_CONTENT_APP_URL}/signs?editSignId=${sign.id}&redirectUrl=${encodedRedirectUrl}`;
    };
    onDeleteProjectContentSign = showDeleteProjectContentSignModal;
  }

  return (
    <div className="has-fab">
      {(readOneCompanyIsLoading)
        ? (<div className="text-center px-5 py-3">
          <Loader size = "2x" />
        </div>)
        : (company) && (
          <CompanyHeader
            company={company}
            projectContentCompany={projectContentCompany}
            onEditCompany={onEditCompany}
            onEditCredits={onEditCompanyCredits}
            onEditSubscriber={onEditCompanySubscriber}
          />)
      }

      {(readUsersIsLoading)
        ? (<div className="text-center px-5 py-3">
          <Loader size="2x" />
        </div>)
        : (<div className="mt-4 px-3 py-4 bg-white shadow-sm">
          <h2 className="h5 border-bottom pb-3 mb-0">
            Users <small>({ users.length })</small>
          </h2>
          <UserListView
            users={users || []}
            onEditUser={showUserEditModal}
            onEmulateUser={onEmulateUser}
            onEditSchedulerPermissions={showSchedulerPermissionsModal}
            onCreatePasswordResetLink={onCreatePasswordResetLink}
            onSendInviteEmail={showSendInviteEmailModal}
            onSendPasswordResetEmail={showPasswordResetEmailModal}
            onDelete={(user) => showUserDeleteModal(user, myUser)}
          />
        </div>
        )}

      {(schedulerSigns) && (<div className="mt-4 px-3 py-4 bg-white shadow-sm">
        <h2 className="h5 border-bottom pb-3 mb-0">
          Scheduler Signs <small>({ schedulerSigns.length })</small>
        </h2>
        <SchedulerSignListView
          signs={schedulerSigns}
          canManageSigns={canManageSigns}
          onEdit={(sign) => {
            const encodedRedirectUrl = encodeURIComponent(window.location.href);
            window.location.href = `${REACT_APP_SCHEDULER_APP_URL}/signs/${sign.id}/edit?redirectUrl=${encodedRedirectUrl}`;
          }}
          onDelete={showSchedulerSignDeleteModal}
          onClickSign={(sign) => {
            const encodedRedirectUrl = encodeURIComponent(window.location.href);
            window.location.href = `${REACT_APP_SCHEDULER_APP_URL}/signs/${sign.id}?redirectUrl=${encodedRedirectUrl}`;
          }}
        />
      </div>)}

      {(projectContentSigns) && (<div className="mt-4 px-3 py-4 bg-white shadow-sm">
        <h2 className="h5 border-bottom pb-3 mb-0">
          Project Content Signs <small>({ projectContentSigns.length })</small>
        </h2>
        <ProjectContentSignListView
          signs={projectContentSigns}
          canManageSigns={canManageSigns}
          onEdit={onEditProjectContentSign}
          onDelete={onDeleteProjectContentSign}
        />
      </div>)}

      <FloatingActionButton
        options={floatingActionButtonOptions}
      />
      {/* edit company modal */}
      {(company) && (<CompanyEditModal
        isVisible={isCompanyEditModalVisible}
        onHide={hideCompanyEditModal}
        company={company}
        myUser={myUser}
        signManufacturers={signManufacturers}
        onSubmit={submitCompanyEdit}
        isSubmitting={updateOneCompanyIsLoading}
      />)}

      {/* edit company credits modal */}
      {(company && projectContentCompany) && (<CompanyEditCreditsModal
        isVisible={isCompanyEditCreditsModalVisible}
        onHide={hideCompanyEditCreditsModal}
        projectContentCompany={projectContentCompany}
        company={company}
        onSubmit={submitCreditsUpdate}
        isSubmitting={updateCreditsIsLoading}
      />)}

      {/* edit company subscriber modal */}
      {(company && projectContentCompany) && (<CompanyEditSubscriberModal
        isVisible={isCompanyEditSubscriberModalVisible}
        onHide={hideCompanyEditSubscriberModal}
        projectContentCompany={projectContentCompany}
        company={company}
        onSubmit={submitSubscriberUpdate}
        isSubmitting={updateProjectContentSubscriberIsLoading}
      />)}

      {/* invite user modal */}
      <UserInviteModal
        isVisible={isUserInviteModalVisible}
        onHide={hideUserInviteModal}
        onSubmit={(user) => submitUserInvite(user, companyId)}
        isSubmitting={createUserIsLoading}
        schedulerSigns={schedulerSigns}
      />
      {/* edit user modal */}
      {(userToEdit) && (<UserEditModal
        isVisible={isUserEditModalVisible}
        onHide={hideUserEditModal}
        user={userToEdit}
        onSubmit={(user) => submitUserEdit(user, users)}
        isSubmitting={updateUserIsLoading}
      />)}

      {/* edit scheduler sign permissions modal */}
      {(userToEditPermissions) && (<SchedulerPermissionsModal
        isVisible={isSchedulerPermissionsModalVisible}
        onHide={hideSchedulerPermissionsModal}
        user={userToEditPermissions}
        schedulerSigns={schedulerSigns}
        isSubmitting={updateSchedulerSignPermissionsIsLoading}
        onSubmit={submitSchedulerPermissions}
      />)}

      {/* password reset link modal */}
      <PasswordResetLinkModal
        isVisible={isPasswordReseLinktModalVisible}
        onHide={hidePasswordResetLinkModal}
        user={userToPasswordResetLink}
      />

      {/* delete user modal */}
      <ConfirmationModal
        show={isUserDeleteModalVisible}
        title="Confirm Delete"
        onHide={hideUserDeleteModal}
        onConfirmation={submitUserDelete}
        confirmButtonColor="danger"
        isSubmitting={deleteOneUserIsLoading}
      >
        Are you sure you want to delete the user <strong>{userToDelete?.email || '-'}</strong>? This user will be permanently deleted.
      </ConfirmationModal>

      {/* send invite email modal */}
      <ConfirmationModal
        show={isSendInviteEmailModalVisible}
        title="Confirm Invite Email Send"
        onHide={hideSendInviteEmailModal}
        onConfirmation={submitSendInviteEmail}
        isSubmitting={sendInviteEmailIsLoading}
      >
        Are you sure you want to send an invite email to <strong>{userToSendInviteEmail?.email || '-'}</strong>?
      </ConfirmationModal>

      {/* send password reset email modal */}
      <ConfirmationModal
        show={isPasswordResetEmailModalVisible}
        title="Confirm Password Reset Send"
        onHide={hidePasswordResetEmailModal}
        onConfirmation={submitSendPasswordResetEmail}
        isSubmitting={sendPasswordResetEmailIsLoading}
      >
        Are you sure you want to send a password reset email to <strong>{userToSendPasswordResetEmail?.email || '-'}</strong>?
      </ConfirmationModal>

      {/* delete scheduler sign modal */}
      <ConfirmationModal
        show={isSchedulerSignDeleteModalVisible}
        title="Confirm Sign Delete"
        onHide={hideSchedulerSignDeleteModal}
        onConfirmation={submitSchedulerSignDelete}
        isSubmitting={deleteSchedulerSignIsLoading}
        confirmButtonColor="danger"
      >
        Are you sure you want to permanently delete the scheduler sign <strong>{schedulerSignToDelete?.name || '-'}</strong>?
      </ConfirmationModal>

      {/* admin delete project content sign modal */}
      <ConfirmationModal
        show={isDeleteProjectContentSignModalVisible}
        title="Delete Project Content Sign"
        onHide={hideDeleteProjectContentSignModal}
        onConfirmation={hideDeleteProjectContentSignModal}
      >
        Please contact Project Content in order to delete a sign.
      </ConfirmationModal>
    </div>
  );
};

export default CompanyDetailsPage;
