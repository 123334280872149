import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IProjectContentSubscriberUpdateRequest } from '@/types/company';
import { useCallApi } from '@/hooks';

const useUpdateProjectContentSubscriber = () => {
  const [updatedProjectContentSubscriber, setUpdatedProjectContentSubscriber] = useState<Record<string, never> | null>(null);
  const [putProjectContentSubscriberApiConfig, setPutProjectContentSubscriberApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateProjectContentSubscriberResponse,
    loading: updateProjectContentSubscriberIsLoading,
  } = useCallApi(putProjectContentSubscriberApiConfig, {
    successMessage: 'Company Subscriber Updated',
    errorMessage: 'Unable to update company subscriber',
    axios: 'projectContentAxios',
  });

  const updateProjectContentSubscriber = ({
    companyId,
    subscriberAuthId,
  }: IProjectContentSubscriberUpdateRequest) => {
    setPutProjectContentSubscriberApiConfig({
      url: `/company/${companyId}/subscriber-user`,
      method: 'put',
      data: {
        subscriberAuthId,
      },
    });
  };

  useEffect(() => {
    if (updateProjectContentSubscriberResponse) setUpdatedProjectContentSubscriber({});
  }, [updateProjectContentSubscriberResponse]);

  return {
    updateProjectContentSubscriber,
    updatedProjectContentSubscriber,
    updateProjectContentSubscriberIsLoading,
  };
};

export default useUpdateProjectContentSubscriber;
