import React, { PropsWithChildren } from 'react';
import { useMyUser } from '@/context/myUser';
import UnauthorizedPage from '@/components/UnauthorizedPage';

interface ProtectedRouteByRolesProps {
  allowedRoles: string[],
}

const ProtectedRouteByRoles = ({
  allowedRoles,
  children,
}: PropsWithChildren<ProtectedRouteByRolesProps>) => {
  const { myUser } = useMyUser();

  if (!myUser) return <></>;

  if (!allowedRoles.includes(myUser.role)) return <UnauthorizedPage />;

  return (<>
    {children}
  </>);
};

export default ProtectedRouteByRoles;
