import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownAZ, faArrowDownWideShort, faArrowDownZA, faBuilding } from '@fortawesome/free-solid-svg-icons';

import { ICompanyResponse } from '@/types/company';
import SimplePagination from '@/components/SimplePagination';
import { useToggle } from '@/hooks';
import useMyUser from '@/context/myUser/useMyUser';

import { useCreateCompany, useDeleteOneCompany, useReadCompanies } from '@/hooks/company';
import Loader from '@/components/Loader';
import Search from '@/components/Search';
import FloatingActionButton, { FloatingActionButtonOptions } from '@/components/FloatingActionButton';
import ConfirmationModalWithCheckbox from '@/components/ConfirmationModalWithCheckbox';
import CompanyCreateModal from '@/features/Admin/components/CompanyCreateModal';
import CompanyListView from '@/features/Admin/components/CompanyListView';
import { ROLE_ADMIN } from '@/constants';
import { sortOption } from '@/types/dropdown';
import { useEmulateUser } from '@/hooks/user';

import { useReadSignManufacturers } from '@/hooks/signManufacturer';

const sortValues: sortOption[] = [
  {
    name: 'A - Z',
    field: 'name',
    direction: 'asc',
    icon: faArrowDownAZ,
  },
  {
    name: 'Z - A',
    field: 'name',
    direction: 'desc',
    icon: faArrowDownZA,
  },
  {
    name: 'Date Created',
    field: 'createdAt',
    direction: 'desc',
    icon: faArrowDownWideShort,
  },
];

const PER_PAGE = 40;

const CompanyListPage = () => {
  const routerNavigate = useNavigate();
  const { myUser } = useMyUser();

  const {
    createCompany,
    createdCompany,
    createCompanyIsLoading,
  } = useCreateCompany();

  const {
    readCompanies,
    companies,
    companiesTotal,
    readCompaniesIsLoading,
  } = useReadCompanies();

  const {
    deleteOneCompany,
    deletedCompany,
  } = useDeleteOneCompany();

  const {
    readSignManufacturers,
    signManufacturers,
  } = useReadSignManufacturers();

  const {
    emulateUser,
  } = useEmulateUser();

  const { show: showConfirmDeleteModal, hide: hideConfirmDeleteModal, isVisible: isConfirmDeleteModalVisible } = useToggle();
  const { show: showCreateCompanyModal, hide: hideCreateCompanyModal, isVisible: isCreateCompanyModalVisible } = useToggle();

  const [companyToDelete, setCompanyToDelete] = useState<ICompanyResponse | null>(null);
  const [searchString, setSearchString] = useState<string>('');
  const [sortValue, setSortValue] = useState(sortValues[0]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!createdCompany) return;

    hideCreateCompanyModal();
    routerNavigate(`/admin/companies/${createdCompany.id}`);
  }, [createdCompany]);

  useEffect(() => {
    readCompanies({
      name: searchString !== '' ? searchString : undefined,
      sort: sortValue.field,
      direction: sortValue.direction,
      page,
      perPage: PER_PAGE,
    });

    readSignManufacturers();
  }, [searchString, sortValue, deletedCompany, createdCompany, page]);

  useEffect(() => {
    if (!deletedCompany) return;
    setCompanyToDelete(null);
  }, [deletedCompany]);

  const onEmulateCompany = (company: ICompanyResponse) => {
    const firstAdminUserInCompany = company.users.find((user) => user.role === ROLE_ADMIN);
    const firstUserInCompany = company.users.length > 0 ? company.users[0] : undefined;

    if (firstAdminUserInCompany) {
      firstAdminUserInCompany.company = company;
      emulateUser(firstAdminUserInCompany);
    } else if (firstUserInCompany) {
      firstUserInCompany.company = company;
      emulateUser(firstUserInCompany);
    }
  };

  const onDeleteCompany = (company: ICompanyResponse) => {
    setCompanyToDelete(company);
    showConfirmDeleteModal();
  };

  const deleteCompany = () => {
    if (!companyToDelete) return;
    deleteOneCompany(companyToDelete.id);
    setCompanyToDelete(null);
    hideConfirmDeleteModal();
  };

  const floatingActionButtonOptions: FloatingActionButtonOptions = [
    {
      icon: faBuilding,
      text: 'Company',
      onClick: showCreateCompanyModal,
    },
  ];

  if (!myUser) return <></>;

  return (
    <div className="has-fab">
      <div className="px-3 py-4 bg-white shadow-sm">
        <Search
          setSearchString={(searchValue) => {
            setPage(1);
            setSearchString(searchValue);
          }}
          searchString={searchString}
          placeholder="Search all Companies"
          searchId="search-companies"
        />
        <div className="d-flex justify-content-end mt-3">
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-primary"
            >
              <FontAwesomeIcon icon={sortValue.icon} />
            </Dropdown.Toggle>
            <Dropdown.Menu
              align="end"
            >
              {sortValues.map((option) => {
                return (<Dropdown.Item
                  key={option.name}
                  onClick={() => setSortValue(option)}
                  active={option.name === sortValue.name}
                >
                  <FontAwesomeIcon
                    className="me-2"
                    icon={option.icon}
                  />
                  <span>{option.name}</span>
                </Dropdown.Item>);
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="mt-2 px-3 py-4 bg-white shadow-sm">
        <div className="row border-bottom pb-1">
          <div className="col-12 col-sm-6 pb-2 d-flex align-items-center">
            <h2 className="h5 mb-0">
              Companies <small>({ companiesTotal.toLocaleString() })</small>
            </h2>
          </div>
          <div className="col-12 col-sm-6 pb-2 d-flex justify-content-end">
            <SimplePagination
              total={companiesTotal}
              page={page}
              perPage={PER_PAGE}
              onPage={(newPage) => {
                setPage(newPage);
              }}
            />
          </div>
        </div>
        {(readCompaniesIsLoading)
          ? (<div className="text-center px-5 py-3">
            <Loader size="2x" />
          </div>)
          : (<CompanyListView
            companies={companies}
            onEmulate={onEmulateCompany}
            onEdit={(company) => routerNavigate(`/admin/companies/${company.id}`)}
            onDelete={onDeleteCompany}
          />)}
        <div className="mt-3 d-flex justify-content-end">
          <SimplePagination
            total={companiesTotal}
            page={page}
            perPage={PER_PAGE}
            onPage={(newPage) => {
              setPage(newPage);
            }}
          />
        </div>
      </div>
      <FloatingActionButton
        options={floatingActionButtonOptions}
      />
      <CompanyCreateModal
        isVisible={isCreateCompanyModalVisible}
        myUser={myUser}
        signManufacturers={signManufacturers}
        onHide={hideCreateCompanyModal}
        onSubmit={(values) => {
          createCompany(values);
          hideCreateCompanyModal();
        }}
        isSubmitting={createCompanyIsLoading}
      />
      <ConfirmationModalWithCheckbox
        show={isConfirmDeleteModalVisible}
        title="Confirm Delete"
        onHide={hideConfirmDeleteModal}
        onConfirmation={deleteCompany}
        confirmButtonColor="danger"
      >
        <p>Are you sure you want to delete this company?</p>
        <p>
          Once deleted, the users of this company will no longer
          have access to the project content system.
        </p>
      </ConfirmationModalWithCheckbox>

    </div>
  );
};

export default CompanyListPage;
