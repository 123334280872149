import React from 'react';
import { Formik, FormikProps } from 'formik';
import Modal from 'react-bootstrap/esm/Modal';
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { ASSIGNABLE_SIGNUP_TYPES, ASSIGNABLE_SIGNUP_TYPES_DICT } from '@/features/Admin/constants';
import { ICompanyCreateRequest, ICompanyCreateRequestForm } from '@/types/company';
import { IUserResponse } from '@/types/user';

import { ROLE_SUPER_ADMIN, SIGNUP_TYPE_NEXTLED } from '@/constants';
import { ISignManufacturerResponse } from '@/types/signManufacturer';

interface CompanyCreateModalProps {
  isVisible: boolean,
  myUser: IUserResponse,
  signManufacturers: ISignManufacturerResponse[],
  onHide: () => void,
  onSubmit: (company: ICompanyCreateRequest) => void,
  isSubmitting?: boolean,
}

const CompanyCreateModal = ({
  isVisible,
  myUser,
  signManufacturers,
  onHide,
  onSubmit,
  isSubmitting,
}: CompanyCreateModalProps) => {
  const [firstSignManufacturer = { id: undefined }] = signManufacturers;
  const initialValues: ICompanyCreateRequestForm = {
    name: '',
    signManufacturerId: firstSignManufacturer.id,
    manufacturerSignupType: SIGNUP_TYPE_NEXTLED,
  };

  return (
    <Modal show={isVisible} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create New Company</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            handleChange,
            handleBlur,
            touched,
            errors,
          }: FormikProps<ICompanyCreateRequestForm>) => {
            const companyPCSignupExperienceScreenshot =
            values.manufacturerSignupType && ASSIGNABLE_SIGNUP_TYPES_DICT[values.manufacturerSignupType]
              ? ASSIGNABLE_SIGNUP_TYPES_DICT[values.manufacturerSignupType].screenshot : null;

            return (
              <Form
                id="create-company-form"
                onSubmit={handleSubmit}
              >
                <Form.Group className="mb-3" controlId="name">
                  <FloatingLabel label="Company Name">
                    <Form.Control
                      type="name"
                      placeholder="Company Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="name"
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name && touched.name
                        ? errors.name
                        : 'Enter your Company Name.'}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                {/* Allows the user to signup to a sign manufacturer's Project Content plan (NextLED) */}
                {myUser.role === ROLE_SUPER_ADMIN && (
                  <Form.Group>
                    <Form.Label>Verified Sign Manufacturer</Form.Label>
                    <Form.Select
                      className="mb-4"
                      name="signManufacturerId"
                      defaultValue={values.signManufacturerId}
                      onChange={(e) => setFieldValue('signManufacturerId', parseInt(e.target.value, 10))}
                    >
                      <option>N/A</option>
                      {signManufacturers.map((signManufacturer) => {
                        return (<option
                          key={signManufacturer.id}
                          value={signManufacturer.id}
                        >
                          {signManufacturer.name}
                        </option>);
                      })}
                    </Form.Select>
                    <Form.Label>Project Content Signup Experience</Form.Label>
                    <Form.Select
                      name="manufacturerSignupType"
                      defaultValue={values.manufacturerSignupType}
                      onChange={(e) => setFieldValue('manufacturerSignupType', e.target.value)}
                    >
                      {ASSIGNABLE_SIGNUP_TYPES.map((signupType) => {
                        return (<option
                          key={signupType.value}
                          value={signupType.value}
                        >
                          {signupType.text}
                        </option>);
                      })}
                    </Form.Select>
                    {companyPCSignupExperienceScreenshot ?
                      <img className="w-100 mt-3 p-1" src={companyPCSignupExperienceScreenshot} />
                      : null}
                  </Form.Group>
                )}
              </Form>
            );
          }}
        </Formik>

      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          form="create-company-form"
          type="submit"
          variant="primary"
          disabled={isSubmitting}
        >
          {isSubmitting
            ? <FontAwesomeIcon icon={faCircleNotch} spin />
            : 'Create'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompanyCreateModal;
