import React from 'react';
import { Formik, FormikProps } from 'formik';
import Modal from 'react-bootstrap/esm/Modal';
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { ICompanyResponse, IProjectContentSubscriberUpdateRequest, IProjectContentCompanyResponse } from '@/types/company';

interface CompanyEditSubscriberModalProps {
  isVisible: boolean,
  onHide: () => void,
  company: ICompanyResponse,
  projectContentCompany: IProjectContentCompanyResponse,
  onSubmit: (newSubscriberUser: IProjectContentSubscriberUpdateRequest) => void,
  isSubmitting?: boolean,
}

interface IProjectContentEditSubscriberRequestForm {
  subscriberAuthId?: string,
}

const CompanyEditSubscriberModal = ({
  isVisible,
  onHide,
  company,
  projectContentCompany,
  onSubmit,
  isSubmitting,
}: CompanyEditSubscriberModalProps) => {
  const existingSubscriber = projectContentCompany
    && projectContentCompany.subscriberUser
    && projectContentCompany.subscriberUser.authId
    && company.users.find((user) => user.id === projectContentCompany.subscriberUser!.authId)
    ? company.users.find((user) => user.id === projectContentCompany.subscriberUser!.authId)!
    : null;

  const userOptions = company.users.map((user) => ({
    label: user.email,
    value: user.id,
  }));

  const schema = Yup.object().shape({
    subscriberAuthId: Yup.string()
      .required('Subscriber is required'),
  });

  const initialValues: IProjectContentEditSubscriberRequestForm = {
    subscriberAuthId: existingSubscriber ? existingSubscriber.id : undefined,
  };

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        if (isSubmitting) return;

        onHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Subscriber for {company.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          A company&apos;s subscriber is the account that can manage the Project Content subscription plan.
        </p>
        <p>
          Current Subscriber: <strong>{existingSubscriber ? existingSubscriber.email : 'N/A'}</strong>
        </p>
        <p>
          If your company has an existing subscription, the existing subscription will be transferred to the new subscriber.
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            if (!values.subscriberAuthId) return;

            onSubmit({
              companyId: projectContentCompany.id,
              subscriberAuthId: values.subscriberAuthId,
            });
          }}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            touched,
            errors,
            handleBlur,
          }: FormikProps<IProjectContentEditSubscriberRequestForm>) => (
            <Form
              id="edit-company-subscriber-form"
              onSubmit={handleSubmit}
            >
              <Form.Group className="mb-3">
                <Form.Label>New Subscriber</Form.Label>
                <Form.Select
                  name="new-subscriber"
                  defaultValue={values.subscriberAuthId}
                  onChange={(e) => setFieldValue('subscriberAuthId', e.target.value)}
                  isValid={touched.subscriberAuthId && !errors.subscriberAuthId}
                  isInvalid={!!errors.subscriberAuthId && touched.subscriberAuthId}
                  onBlur={handleBlur}
                >
                  <option
                    disabled
                    selected
                  >
                    N/A
                  </option>
                  {(userOptions.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  )))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.subscriberAuthId && touched.subscriberAuthId
                    ? errors.subscriberAuthId
                    : 'Invalid Subscriber'}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          form="edit-company-subscriber-form"
          type="submit"
          variant="primary"
          disabled={isSubmitting}
        >
          {isSubmitting
            ? <FontAwesomeIcon icon={faCircleNotch} spin />
            : 'Save'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompanyEditSubscriberModal;
