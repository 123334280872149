import React from 'react';
import { Formik, FormikProps } from 'formik';
import Modal from 'react-bootstrap/esm/Modal';
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { ASSIGNABLE_SIGNUP_TYPES, ASSIGNABLE_SIGNUP_TYPES_DICT } from '@/features/Admin/constants';
import { ROLE_SUPER_ADMIN, SIGNUP_TYPE_STANDARD } from '@/constants';
import { IUserResponse } from '@/types/user';
import { ICompanyEditRequestForm, ICompanyResponse, ICompanyUpdateRequest } from '@/types/company';
import { ISignManufacturerResponse } from '@/types/signManufacturer';

interface CompanyEditModalProps {
  isVisible: boolean,
  onHide: () => void,
  company: ICompanyResponse,
  myUser: IUserResponse,
  signManufacturers: ISignManufacturerResponse[],
  onSubmit: (company: ICompanyUpdateRequest) => void,
  isSubmitting?: boolean,
}

const CompanyEditModal = ({
  isVisible,
  onHide,
  company,
  myUser,
  signManufacturers,
  onSubmit,
  isSubmitting,
}: CompanyEditModalProps) => {
  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const initialValues: ICompanyEditRequestForm = {
    name: company.name,
    signManufacturerId: company.signManufacturerId || undefined,
    manufacturerSignupType: company.manufacturerSignupType || SIGNUP_TYPE_STANDARD,
  };

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        if (isSubmitting) return;

        onHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit {company.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            const safeSignManufacturerId = values.signManufacturerId || null;
            onSubmit({
              id: company.id,
              name: values.name,
              signManufacturerId: safeSignManufacturerId,
              manufacturerSignupType: values.manufacturerSignupType,
            });
          }}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            touched,
            errors,
            handleBlur,
          }: FormikProps<ICompanyEditRequestForm>) => {
            const companyPCSignupExperienceScreenshot = ASSIGNABLE_SIGNUP_TYPES_DICT[values.manufacturerSignupType]
              ? ASSIGNABLE_SIGNUP_TYPES_DICT[values.manufacturerSignupType].screenshot : null;

            return (
              <Form
                id="edit-company-form"
                onSubmit={handleSubmit}
              >
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="name"
                    name="name"
                    value={values.name}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                    isValid={touched.name && !errors.name}
                    isInvalid={!!errors.name && touched.name}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && touched.name
                      ? errors.name
                      : 'Invalid Name'}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Allows the user to signup to a sign manufacturer's Project Content plan (NextLED) */}
                {myUser.role === ROLE_SUPER_ADMIN && (
                  <Form.Group>
                    <Form.Label>Verified Sign Manufacturer</Form.Label>
                    <Form.Select
                      className="mb-4"
                      name="signManufacturerId"
                      defaultValue={values.signManufacturerId}
                      onChange={(e) => setFieldValue('signManufacturerId', parseInt(e.target.value, 10))}
                    >
                      <option value={0}>N/A</option>
                      {signManufacturers.map((signManufacturer) => {
                        return (<option
                          key={signManufacturer.id}
                          value={signManufacturer.id}
                        >
                          {signManufacturer.name}
                        </option>);
                      })}
                    </Form.Select>
                    <Form.Label>Project Content Signup Experience</Form.Label>
                    <Form.Select
                      name="manufacturerSignupType"
                      defaultValue={values.manufacturerSignupType}
                      onChange={(e) => setFieldValue('manufacturerSignupType', e.target.value)}
                    >
                      {ASSIGNABLE_SIGNUP_TYPES.map((signupType) => {
                        return (<option
                          key={signupType.value}
                          value={signupType.value}
                        >
                          {signupType.text}
                        </option>);
                      })}
                    </Form.Select>
                    {companyPCSignupExperienceScreenshot ?
                      <img className="w-100 mt-3 p-1" src={companyPCSignupExperienceScreenshot} />
                      : null}
                  </Form.Group>
                )}
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          form="edit-company-form"
          type="submit"
          variant="primary"
          disabled={isSubmitting}
        >
          {isSubmitting
            ? <FontAwesomeIcon icon={faCircleNotch} spin />
            : 'Save'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompanyEditModal;
