
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/esm/Form';
import FormControl from 'react-bootstrap/esm/FormControl';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { useDebounce } from '@/hooks';

interface SearchProps {
  setSearchString: React.Dispatch<React.SetStateAction<string>>,
  searchString: string,
  searchId: string,
  maxWidth?: string,
  placeholder?: string,
}

const DEBOUNCE_WAIT_TIME = 500;

const Search = ({
  setSearchString,
  searchString = '',
  searchId,
  maxWidth = '100%',
  placeholder = 'Search',
}: SearchProps) => {
  const [inputValue, setInputValue] = useState<string>(searchString || '');

  const debouncedInputValue: string = useDebounce<string>(inputValue, DEBOUNCE_WAIT_TIME);

  useEffect(() => {
    setInputValue(searchString);
  }, [searchString]);

  useEffect(() => {
    setSearchString(debouncedInputValue);
  }, [debouncedInputValue]);

  return (
    <div>
      <Form
        className="d-flex"
        onSubmit={(e) => {
          e.preventDefault();
          setInputValue(inputValue);
        }}
      >
        <InputGroup
          style={{
            maxWidth,
          }}
        >
          <InputGroup.Text
            className="border-end-0 bg-transparent text-gray"
            as="label"
            htmlFor={searchId}
          >
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <FormControl
            id={searchId}
            value={inputValue}
            type="search"
            placeholder={placeholder}
            className="border-start-0"
            aria-label="Search"
            onChange={(e) => setInputValue(e.currentTarget.value)}
          />
        </InputGroup>
      </Form>
    </div>
  );
};

export default Search;
