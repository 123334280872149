import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { ICompanyResponse, ICompanyCreateRequest } from '@/types/company';
import { useCallApi } from '@/hooks';

const useCreateCompany = () => {
  const [createdCompany, setCreatedCompany] = useState<ICompanyResponse | null>(null);
  const [postCompanyApiConfig, setPostCompanyApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: createCompanyResponse,
    loading: createCompanyIsLoading,
  } = useCallApi(postCompanyApiConfig, {
    successMessage: 'Company Created',
    errorMessage: 'Unable to create company',
  });

  const createCompany = (newCompany: ICompanyCreateRequest) => {
    setPostCompanyApiConfig({
      url: '/companies',
      method: 'post',
      data: newCompany,
    });
  };

  useEffect(() => {
    setCreatedCompany(createCompanyResponse?.data || null);
  }, [createCompanyResponse]);

  return {
    createCompany,
    createdCompany,
    createCompanyIsLoading,
  };
};

export default useCreateCompany;

