/* eslint-disable no-process-env */
import { faBuilding, faUser } from '@fortawesome/free-regular-svg-icons';

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '@/constants';
import { IUserResponse, UserRole } from '@/types/user';

import { NavItem } from './types';

const REACT_APP_CANVAS_URL = process.env.REACT_APP_CANVAS_URL || '';
const REACT_APP_TEMPLATES_URL = process.env.REACT_APP_TEMPLATES_URL || '';
const REACT_APP_SCHEDULER_APP_URL = `${process.env.REACT_APP_SCHEDULER_APP_URL}/home` || '';

const userMustBeRole = (user: IUserResponse, allowedRoles: UserRole[]) => {
  return allowedRoles.includes(user.role);
};

const onlyAllowSuperAdmin = (user: IUserResponse) => {
  return userMustBeRole(user, [ROLE_SUPER_ADMIN]);
};

const allowSuperAdminAndAdmin = (user: IUserResponse) => {
  return userMustBeRole(user, [ROLE_SUPER_ADMIN, ROLE_ADMIN]);
};

export const HOME_NAV_ITEMS: NavItem[] = [
  {
    name: 'Templates',
    url: REACT_APP_TEMPLATES_URL,
    customIcon: 'template',
    isDisabledOnMobile: true,
    isExternal: true,
  },
  {
    name: 'Canvas',
    url: REACT_APP_CANVAS_URL,
    customIcon: 'wand',
    isDisabledOnMobile: true,
    isExternal: true,
  },
  {
    name: 'Schedule My Sign',
    url: REACT_APP_SCHEDULER_APP_URL,
    customIcon: 'clock',
    isExternal: true,
  },
  {
    name: 'Companies',
    url: '/admin/companies',
    icon: faBuilding,
    allowRule: onlyAllowSuperAdmin,
  },
  {
    name: 'Users',
    url: '/admin/users',
    icon: faUser,
    allowRule: onlyAllowSuperAdmin,
  },
  {
    name: 'My Company',
    url: '/admin/my-company',
    icon: faBuilding,
    allowRule: allowSuperAdminAndAdmin,
  },
];

export const MOBILE_NAV_ITEMS: NavItem[] = [
  {
    name: 'Home',
    url: '/home',
    customIcon: 'home',
  },
  {
    name: 'Companies',
    url: '/admin/companies',
    icon: faBuilding,
    allowRule: onlyAllowSuperAdmin,
  },
  {
    name: 'Users',
    url: '/admin/users',
    icon: faUser,
    allowRule: onlyAllowSuperAdmin,
  },
  {
    name: 'My Company',
    url: '/admin/my-company',
    icon: faBuilding,
    allowRule: allowSuperAdminAndAdmin,
  },
];

export const DASHBOARD_NAV_ITEMS: NavItem[] = [
  {
    name: 'Home',
    url: '/home',
    customIcon: 'home',
  },
  {
    name: 'Companies',
    url: '/admin/companies',
    icon: faBuilding,
    allowRule: onlyAllowSuperAdmin,
  },
  {
    name: 'Users',
    url: '/admin/users',
    icon: faUser,
    allowRule: onlyAllowSuperAdmin,
  },
  {
    name: 'My Company',
    url: '/admin/my-company',
    icon: faBuilding,
    allowRule: allowSuperAdminAndAdmin,
  },
];

export const DASHBOARD_EXTERNAL_NAV_ITEMS: NavItem[] = [
  {
    name: 'Templates',
    url: REACT_APP_TEMPLATES_URL,
    customIcon: 'template',
    isDisabledOnMobile: true,
    isExternal: true,
    className: 'me-2',
  },
  {
    name: 'Canvas',
    url: REACT_APP_CANVAS_URL,
    customIcon: 'wand',
    isDisabledOnMobile: true,
    isExternal: true,
    className: 'me-3',
  },
  {
    name: 'Scheduler',
    url: REACT_APP_SCHEDULER_APP_URL,
    customIcon: 'clock',
    isExternal: true,
    className: 'me-3',
  },
];
