import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { useLocation, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MOBILE_NAV_ITEMS } from '@/layout/constants';
import CustomIcon from '@/components/CustomIcon';
import { useMyUser } from '@/context/myUser';
import { NavItem } from '@/layout/types';

import './BottomNav.scss';

/**
 * Bottom Navigation for Mobile
 *
 * @returns {JSX.Element}
 */
export const BottomNav = (): JSX.Element => {
  const activeLocation = useLocation();
  const { myUser } = useMyUser();
  const [filteredNavItems, setFilteredNavItems] = useState<NavItem[]>([]);

  useEffect(() => {
    if (!myUser) {
      setFilteredNavItems([]);
      return;
    }

    const filteredItems = MOBILE_NAV_ITEMS.filter((item) => {
      if (!item.allowRule) return true;

      return item.allowRule(myUser);
    });

    setFilteredNavItems(filteredItems);
  }, [myUser]);

  return (
    <Nav
      justify
      variant="tabs"
      defaultActiveKey={filteredNavItems.length > 0 ? filteredNavItems[0].url : undefined}
      activeKey={activeLocation.pathname}
      className="fixed-bottom bg-dark d-lg-none bottom-nav"
    >
      {filteredNavItems.map((i) => {
        return (<Nav.Item key={i.name}>
          <NavLink
            className="nav-link rounded-0 px-0"
            to={i.url}
          >
            <div className="nav-icon">
              {(i.customIcon) && (
                <CustomIcon
                  style={{
                    height: 18,
                    display: 'inline-block',
                  }}
                  type={i.customIcon}
                />
              )}
              {(!i.customIcon && i.icon) && (
                <FontAwesomeIcon
                  icon={i.icon}
                  style={{
                    fontSize: 20,
                  }}
                />
              )}
            </div>
            <br />
            {i.name}
          </NavLink>
        </Nav.Item>);
      })}
    </Nav>
  );
};

export default BottomNav;
