import { useEffect } from 'react';

import { useToggle } from '@/hooks';
import { IProjectContentSubscriberUpdateRequest } from '@/types/company';
import { useUpdateProjectContentSubscriber } from '@/hooks/company';

const useCompanyEditSubscriberModal = () => {
  const { show: showCompanyEditSubscriberModal, hide: hideCompanyEditSubscriberModal, isVisible: isCompanyEditSubscriberModalVisible } = useToggle();

  const {
    updateProjectContentSubscriber,
    updatedProjectContentSubscriber,
    updateProjectContentSubscriberIsLoading,
  } = useUpdateProjectContentSubscriber();

  const submitSubscriberUpdate = (subscriber: IProjectContentSubscriberUpdateRequest) => {
    updateProjectContentSubscriber(subscriber);
  };

  useEffect(() => {
    if (!updatedProjectContentSubscriber) return;

    hideCompanyEditSubscriberModal();
  }, [updatedProjectContentSubscriber]);

  return {
    showCompanyEditSubscriberModal,
    hideCompanyEditSubscriberModal,
    isCompanyEditSubscriberModalVisible,
    updatedProjectContentSubscriber,
    updateProjectContentSubscriberIsLoading,
    submitSubscriberUpdate,
  };
};

export default useCompanyEditSubscriberModal;
