import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IProjectContentSignResponse } from '@/types/sign';
import { useCallApi } from '@/hooks';

const useReadProjectContentSigns = () => {
  const [projectContentSigns, setProjectContentSigns] = useState<IProjectContentSignResponse[]>([]);
  const [getProjectContentSignListApiConfig, setGetProjectContentSignListApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readProjectContentSignsResponse,
    loading: readProjectContentSignsIsLoading,
  } = useCallApi(getProjectContentSignListApiConfig, {
    errorMessage: 'Error loading project content signs',
    axios: 'projectContentAxios',
  });

  const readProjectContentSigns = (companyId: string) => {
    setGetProjectContentSignListApiConfig({
      url: `/admin/company-signs/${companyId}`,
      method: 'get',
    });
  };

  useEffect(() => {
    setProjectContentSigns(readProjectContentSignsResponse?.data || []);
  }, [readProjectContentSignsResponse]);

  return {
    readProjectContentSigns,
    projectContentSigns,
    readProjectContentSignsIsLoading,
  };
};

export default useReadProjectContentSigns;
