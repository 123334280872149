import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useCallApi } from '@/hooks';

const useDeleteOneCompany = () => {
  const [deletedCompany, setDeletedCompany] = useState<Record<string, never> | null>(null);
  const [deleteCompanyApiConfig, setDeleteCompanyApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: deleteOneCompanyResponse,
    loading: deleteOneCompanyIsLoading,
  } = useCallApi(deleteCompanyApiConfig, {
    successMessage: 'Company Deleted',
    errorMessage: 'Unable to delete company',
  });

  const deleteOneCompany = (companyId: string) => {
    setDeleteCompanyApiConfig({
      url: `/companies/${companyId}`,
      method: 'delete',
    });
  };

  useEffect(() => {
    if (deleteOneCompanyResponse) setDeletedCompany({});
  }, [deleteOneCompanyResponse]);

  return {
    deleteOneCompany,
    deletedCompany,
    deleteOneCompanyIsLoading,
  };
};

export default useDeleteOneCompany;
