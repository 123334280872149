import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { ISignManufacturerResponse } from '@/types/signManufacturer';
import { useCallApi } from '@/hooks';

const useReadSignManufacturers = () => {
  const [signManufacturers, setSignManufacturers] = useState<ISignManufacturerResponse[]>([]);
  const [getSignManufacturerListApiConfig, setGetSignManufacturerListApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readSignManufacturersResponse,
    loading: readSignManufacturersIsLoading,
  } = useCallApi(getSignManufacturerListApiConfig, {
    errorMessage: 'Unable to load Sign Manufacturers',
  });

  const readSignManufacturers = () => {
    setGetSignManufacturerListApiConfig({
      url: '/sign-manufacturers',
      method: 'get',
    });
  };

  useEffect(() => {
    if (!readSignManufacturersResponse) return;

    setSignManufacturers(readSignManufacturersResponse.data || []);
  }, [readSignManufacturersResponse]);

  return {
    readSignManufacturers,
    signManufacturers,
    readSignManufacturersIsLoading,
  };
};

export default useReadSignManufacturers;

