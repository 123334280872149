import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useCallApi } from '@/hooks';

const useDeleteSchedulerSign = () => {
  const [deletedSchedulerSign, setDeletedSchedulerSign] = useState<Record<string, never> | null>(null);
  const [deleteSchedulerSignApiConfig, setSchedulerDeleteSignApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: deleteSchedulerSignResponse,
    loading: deleteSchedulerSignIsLoading,
  } = useCallApi(deleteSchedulerSignApiConfig, {
    successMessage: 'success',
    errorMessage: 'error',
    axios: 'schedulerAxios',
  });

  const deleteSchedulerSign = (signId: number) => {
    setSchedulerDeleteSignApiConfig({
      url: `/signs/${signId}`,
      method: 'delete',
    });
  };

  useEffect(() => {
    if (deleteSchedulerSignResponse) setDeletedSchedulerSign({});
  }, [deleteSchedulerSignResponse]);

  return {
    deleteSchedulerSign,
    deletedSchedulerSign,
    deleteSchedulerSignIsLoading,
  };
};

export default useDeleteSchedulerSign;
