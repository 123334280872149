import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IUserResponse } from '@/types/user';
import { useCallApi } from '@/hooks';
import { IndexApiParams } from '@/types/api';

const useReadUsers = () => {
  const [users, setUsers] = useState<IUserResponse[]>([]);
  const [usersTotal, setUsersTotal] = useState<number>(0);
  const [getUsersApiConfig, setGetUsersApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readUsersResponse,
    loading: readUsersIsLoading,
  } = useCallApi(getUsersApiConfig, {
    errorMessage: 'Unable to load users',
  });

  const readUsers = (params: IndexApiParams = {}) => {
    setGetUsersApiConfig({
      url: '/users',
      method: 'get',
      params,
    });
  };

  useEffect(() => {
    if (!readUsersResponse) return;

    setUsers(readUsersResponse?.data?.data || []);
    setUsersTotal(readUsersResponse?.data?.meta?.total || 0);
  }, [readUsersResponse]);

  return {
    readUsers,
    users,
    usersTotal,
    readUsersIsLoading,
  };
};

export default useReadUsers;

