/* eslint-disable no-process-env */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';

import { MyUserProvider } from '@/context/myUser';
import '@/styles/index.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const domain = process.env.REACT_APP_AUTH0_ISSUER || '';
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || '';
const redirectUri = `${process.env.REACT_APP_APP_URL}/admin`;
const packageVersion = process.env.PACKAGE_VERSION;
const auth0CookieDomain = process.env.REACT_APP_AUTH0_COOKIE_DOMAIN;

// eslint-disable-next-line no-console
console.log(`%cHub Software Version: ${packageVersion}`, 'background: #3d3d3d; color: #fff; padding: 4px 8px;');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          audience,
          'redirect_uri': redirectUri,
        }}
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
        cookieDomain={auth0CookieDomain}
      >
        <MyUserProvider>
          <App />
        </MyUserProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
