import React from 'react';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/esm/Modal';
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { IUserCreateRequestForm, IUserCreateRequest } from '@/types/user';
import {
  ASSIGNABLE_USER_ROLES,
  ASSIGNABLE_SIGNUP_TYPES_DICT,
} from '@/features/Admin/constants';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_USER } from '@/constants';
import { IScheduleSignResponse } from '@/types/sign';

interface UserInviteModalProps {
  isVisible: boolean,
  onHide: () => void,
  onSubmit: (values: IUserCreateRequest) => void,
  isSubmitting?: boolean,
  schedulerSigns?: IScheduleSignResponse[],
}

const UserInviteModal = ({
  isVisible,
  onHide,
  onSubmit,
  isSubmitting = false,
  schedulerSigns = [],
}: UserInviteModalProps) => {
  const schema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email Required'),
    manufacturerSignupType: Yup.string().oneOf(Object.keys(ASSIGNABLE_SIGNUP_TYPES_DICT)),
    role: Yup.string().required('Please choose a role'),
  });

  const initialValues: IUserCreateRequestForm = {
    email: '',
    role: ROLE_USER,
    sendInviteEmail: true,
    schedulerSignPermissions: schedulerSigns.map((sign) => sign.id),
  };

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        if (isSubmitting) return;
        onHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Invite User</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          values,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }: FormikProps<IUserCreateRequestForm>) => {
          return (
            <Modal.Body>
              <Form
                id="create-user-form"
                noValidate
                onSubmit={handleSubmit}
              >
                <Form.Group>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                  />
                  <ErrorMessage name="email">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    name="role"
                    defaultValue={values.role}
                    onChange={(e) => setFieldValue('role', e.target.value)}
                  >
                    {ASSIGNABLE_USER_ROLES.map((role) => {
                      return <option key={role} value={role}>{role}</option>;
                    })}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Check
                    id="send-invite-email"
                    checked={values.sendInviteEmail}
                    label="Send Invite Email"
                    onChange={(e) => setFieldValue('sendInviteEmail', e.target.checked)}
                  />
                </Form.Group>

                <hr />

                <h2 className="h5">
                  Scheduler Sign Permissions
                </h2>
                <p>
                  {(values.role === ROLE_ADMIN || values.role === ROLE_SUPER_ADMIN)
                    ? <>Admins have full access to all signs.</>
                    : <>Select the Scheduler signs the user will have access to.</>
                  }
                </p>
                {(values.role === ROLE_USER) && (<>
                  <div className="mb-2">
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        if (values.schedulerSignPermissions.length === schedulerSigns.length) {
                          // deselect all
                          setFieldValue('schedulerSignPermissions', []);
                        } else {
                          // select all
                          setFieldValue('schedulerSignPermissions', schedulerSigns.map((sign) => sign.id));
                        }
                      }}
                    >
                      {(values.schedulerSignPermissions.length === schedulerSigns.length)
                        ? (<>Deselect All</>)
                        : (<>Select All</>)
                      }
                    </Button>
                  </div>
                  <ListGroup as="div">
                    {(schedulerSigns.length > 0) && (
                      schedulerSigns.map((sign) => (
                        <ListGroupItem
                          key={sign.id}
                          as="label"
                          className="d-flex cursor-pointer"
                        >
                          <Form.Check
                            className="me-2"
                            value={sign.id}
                            checked={values.schedulerSignPermissions.some((signId) => sign.id === signId)}
                            onChange={(changeEvent) => {
                              const checked = changeEvent.target.checked;

                              const checkedList = checked
                                ? [...values.schedulerSignPermissions, sign.id]
                                : values.schedulerSignPermissions.filter((id) => id !== sign.id);

                              setFieldValue('schedulerSignPermissions', checkedList);
                            }}
                          />
                          <div>
                            <span>
                              {sign.name}
                            </span><br />
                            <span className="text-muted small">
                              { sign.city }
                              {(sign.city) && (<>&nbsp;,&nbsp;</>)}
                              { sign.state }
                              {(sign.city || sign.state) && (<>&nbsp;|&nbsp;</>)}
                              { sign.width } x { sign.height }
                            </span>
                          </div>
                        </ListGroupItem>
                      ))
                    )}
                  </ListGroup>
                </>)}
              </Form>
            </Modal.Body>
          );
        }}
      </Formik>

      <Modal.Footer>
        <Button
          variant="light"
          onClick={onHide}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          form="create-user-form"
          type="submit"
          variant="primary"
          disabled={isSubmitting}
        >
          {isSubmitting
            ? <FontAwesomeIcon icon={faCircleNotch} spin />
            : <>Invite</>
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserInviteModal;
