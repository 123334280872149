import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/esm/Button';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import { Link } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import pcLogo from '@/assets/logos/pc_logo_old.png';
import { HOME_NAV_ITEMS } from '@/layout/constants';
import { useWindowSize } from '@/hooks';
import Loader  from '@/components/Loader';
import ConditionalWrapper from '@/components/wrappers/ConditionalWrapper';
import IfElseWrapper from '@/components/wrappers/IfElseWrapper';
import { useMyUser } from '@/context/myUser';
import CustomIcon from '@/components/CustomIcon';
import { NavItem } from '@/layout/types';

const BOOTSTRAP_LG_QUERY_IN_PIXELS = 992;

const Home = () => {
  const { myUser, myUserIsLoading } = useMyUser();

  const windowSize = useWindowSize();

  const [filteredNavItems, setFilteredNavItems] = useState<NavItem[]>([]);

  useEffect(() => {
    if (!myUser) {
      setFilteredNavItems([]);
      return;
    }

    const filteredItems = HOME_NAV_ITEMS.filter((item) => {
      if (!item.allowRule) return true;

      return item.allowRule(myUser);
    });

    setFilteredNavItems(filteredItems);
  }, [myUser]);

  if (myUserIsLoading) {
    return (<div
      className="px-3 py-4"
    >
      <Loader size="2x" />
    </div>);
  }

  const windowIsLessThanLarge = windowSize.width < BOOTSTRAP_LG_QUERY_IN_PIXELS;

  if (!myUser) return <></>;

  return (
    <section>
      <div className="ratio ratio-16x9 bg-dark d-flex align-items-center justify-content-center">
        <img
          className="position-relative"
          style={{
            width: 72,
            height: 79,
          }}
          src={pcLogo}
        />
      </div><div
        className="px-3 py-4 blue-gradient text-white fw-bold text-center"
      >
          Manage your company & users
      </div>
      <div className="bg-white fw-bold">
        {filteredNavItems.map((item) => (
          <ConditionalWrapper
            key={item.name}
            condition={!!item.isDisabledOnMobile && windowIsLessThanLarge}
            wrapper={(children) => (
              <OverlayTrigger
                overlay={<Tooltip>Not available on mobile</Tooltip>}
              >
                {/* this div is needed to allow hover/click to trigger the tooltip */}
                <div
                  className="bg-dark bg-opacity-25"
                >
                  {/* prevent clicks/hover on item */}
                  <div
                    style={{
                      pointerEvents: 'none',
                    }}
                  >
                    {children}
                  </div>
                </div>
              </OverlayTrigger>
            )}
          >
            <IfElseWrapper
              condition={!!item.isExternal}
              wrapperIf={(children) => <a href={item.url}>{children}</a>}
              wrapperElse={(children) => <Link to={item.url}>{children}</Link>}
            >
              <div
                className="px-3"
              >
                <div
                  className="px-2 py-4 d-flex align-items-center justify-content-between border-bottom"
                  key={item.name}
                >
                  <div className="d-flex align-items-center">
                    {(item.customIcon) && (<CustomIcon
                      className="me-3"
                      style={{
                        width: 24,
                        height: 'auto',
                      }}
                      type={item.customIcon}
                    />)}
                    {(!item.customIcon && item.icon) && (
                      <div
                        className="d-inline-flex align-items-center me-3"
                        style={{
                          width: 24,
                          height: 'auto',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={item.icon}
                          style={{
                            width: '100%',
                            fontSize: 20,
                          }}
                        />
                      </div>
                    )}
                    {item.name}
                  </div>
                  <Button
                    variant="link"
                  >
                    <FontAwesomeIcon
                      className="text-gray"
                      icon={faChevronRight}
                    />
                  </Button>
                </div>
              </div>
            </IfElseWrapper>
          </ConditionalWrapper>
        ))}
        <a href="mailto:info@projectcontent.com">
          <div className="px-4 py-4 text-gray">
              Need Help?
          </div>
        </a>
      </div>
    </section>
  );
};

export default Home;
