import React from 'react';
import Button from 'react-bootstrap/esm/Button';

import { IProjectContentSignResponse } from '@/types/sign';
import { useToggle } from '@/hooks';

import ProjectContentSignListItem from './ProjectContentSignListItem';

const INITIAL_SIGN_COUNT_TO_SHOW = 5;

interface ProjectContentSignListViewProps {
  signs: IProjectContentSignResponse[],
  canManageSigns?: boolean,
  onEdit?: (sign: IProjectContentSignResponse) => void,
  onDelete?: (sign: IProjectContentSignResponse) => void,
}

const ProjectContentSignListView = ({
  signs,
  canManageSigns = true,
  onEdit,
  onDelete,
}: ProjectContentSignListViewProps) => {
  const { toggle: toggleShowMore, isVisible: showMore } = useToggle();

  const signCount = signs.length;

  const signsFiltered = signCount > INITIAL_SIGN_COUNT_TO_SHOW && !showMore
    ? signs.slice(0, INITIAL_SIGN_COUNT_TO_SHOW)
    : signs;

  return (
    <section>
      {(signs.length === 0) && (
        <div className="px-2 py-3">
          No Signs Found
        </div>
      )}
      {signsFiltered.map((sign) => (
        <ProjectContentSignListItem
          key={sign.id}
          sign={sign}
          canManageSigns={canManageSigns}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
      {(signCount > INITIAL_SIGN_COUNT_TO_SHOW) && (
        <Button
          className="w-100"
          variant="link"
          onClick={toggleShowMore}
        >
          Show {showMore ? 'Less' : 'More' }
        </Button>
      )}
    </section>
  );
};

export default ProjectContentSignListView;
