import React from 'react';
import Button from 'react-bootstrap/esm/Button';

import { IUserResponse } from '@/types/user';
import { useMyUser } from '@/context/myUser';
import { canMyUserEditRole, canMyUserDeleteUser } from '@/features/Admin/utils';
import { useToggle } from '@/hooks';

import UserListItem from './UserListItem';

const INITIAL_USER_COUNT_TO_SHOW = 5;

interface UserListViewProps {
  users: IUserResponse[],
  onEditUser: (user: IUserResponse) => void,
  onDelete: (user: IUserResponse) => void,
  onEmulateUser?: (user: IUserResponse) => void,
  onEditSchedulerPermissions?: (user: IUserResponse) => void,
  onCreatePasswordResetLink?: (user: IUserResponse) => void,
  onSendInviteEmail?: (user: IUserResponse) => void,
  onSendPasswordResetEmail?: (user: IUserResponse) => void,
  onClickCompany?: (user: IUserResponse) => void,
}

const UserListView = ({
  users,
  onEditUser,
  onDelete,
  onEmulateUser,
  onEditSchedulerPermissions,
  onCreatePasswordResetLink,
  onSendInviteEmail,
  onSendPasswordResetEmail,
  onClickCompany,
}: UserListViewProps) => {
  const { myUser } = useMyUser();
  const { toggle: toggleShowMore, isVisible: showMore } = useToggle();

  const userCount = users.length;

  const usersFiltered = userCount > INITIAL_USER_COUNT_TO_SHOW && !showMore
    ? users.slice(0, INITIAL_USER_COUNT_TO_SHOW)
    : users;

  if (!myUser) return <></>;

  return (
    <section>
      {(users.length === 0) && (
        <div className="px-2 py-3">
          No Users Found
        </div>
      )}
      {usersFiltered.map((user) => {
        const canEditRole = canMyUserEditRole(myUser, user);
        const canDeleteUser = canMyUserDeleteUser(myUser, user);

        return (
          <UserListItem
            key={user.id}
            user={user}
            onEditUser={onEditUser}
            onDelete={onDelete}
            onEmulateUser={onEmulateUser}
            onEditSchedulerPermissions={onEditSchedulerPermissions}
            onCreatePasswordResetLink={onCreatePasswordResetLink}
            onSendInviteEmail={onSendInviteEmail}
            onSendPasswordResetEmail={onSendPasswordResetEmail}
            onClickCompany={onClickCompany}
            disableEditRole={!canEditRole.allowed}
            disableEditRoleReason={canEditRole.notAllowedReason}
            disableDeleteUser={!canDeleteUser.allowed}
            disableDeleteUserReason={canDeleteUser.notAllowedReason}
          />
        );
      })}
      {(userCount > INITIAL_USER_COUNT_TO_SHOW) && (
        <Button
          className="w-100"
          variant="link"
          onClick={toggleShowMore}
        >
          Show {showMore ? 'Less' : 'More' }
        </Button>
      )}
    </section>
  );
};

export default UserListView;
