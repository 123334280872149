import { useState } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useCallApi } from '@/hooks';

const useSendPasswordResetEmail = () => {
  const [sendPasswordResetEmailApiConfig, setSendPasswordResetEmailApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: sendPasswordResetEmailResponse,
    loading: sendPasswordResetEmailIsLoading,
    error: sendPasswordResetEmailError,
  } = useCallApi(sendPasswordResetEmailApiConfig, {
    successMessage: 'Password Reset email sent successfully',
    errorMessage: 'Unable to send password reset email',
  });

  const sendPasswordResetEmail = (userId: string) => {
    setSendPasswordResetEmailApiConfig({
      url: `/users/${userId}/send-password-reset-email`,
      method: 'post',
    });
  };

  return {
    sendPasswordResetEmail,
    sendPasswordResetEmailResponse,
    sendPasswordResetEmailIsLoading,
    sendPasswordResetEmailError,
  };
};

export default useSendPasswordResetEmail;
