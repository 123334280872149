import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { useState, useEffect, useDebugValue } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';

import appAxios from '@/api/appAxios';
import schedulerAxios from '@/api/schedulerAxios';
import projectContentAxios from '@/api/projectContentAxios';
import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@/constants';

interface IState {
  error: {
    status: number,
    message: string,

    // 422 error response from API
    errors: {
      rule: string,
      field: string,
      message: string,
    }[],
  } | null,
  loading: boolean | undefined,
  data: AxiosResponse | null,
}

const appAxiosIntance = 'appAxios';
const schedulerAxiosInstance = 'schedulerAxios';
const projectContentAxiosInstance = 'projectContentAxios';

const axiosDictionary = {
  [appAxiosIntance]: appAxios,
  [schedulerAxiosInstance]: schedulerAxios,
  [projectContentAxiosInstance]: projectContentAxios,
};

type UseCallApi = (config: (AxiosRequestConfig | null), options?: {
  successMessage?: string
  errorMessage?: string
  ignoreError?: boolean
  axios?: typeof appAxiosIntance | typeof schedulerAxiosInstance | typeof projectContentAxiosInstance,

  // status codes that should not show an error message. For example you could
  // pass 404 to ignore toast error messages when model is not found
  ignoreStatusCodes?: number[]
}) => IState

/**
 * A wrapper for api calls that adds an auth token before sending
 * @param config AxiosRequestConfig
 *
 * @returns {*}
 */
const useCallApi: UseCallApi = (config, {
  successMessage,
  errorMessage,
  ignoreError,
  axios = appAxiosIntance,
  ignoreStatusCodes = [],
} = {
  axios: appAxiosIntance,
}) => {
  const [state, setState] = useState<IState>({
    error: null,
    loading: false,
    data: null,
  });

  const { getAccessTokenSilently } = useAuth0();

  const callApi = async () => {
    try {
      setState({
        ...state,
        loading: true,
      });

      const accessToken = await getAccessTokenSilently();

      const instance = axiosDictionary[axios];
      const result = await (instance).request({
        ...config,
        params: {
          ...config?.params,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setState({
        ...state,
        data: result,
        error: null,
        loading: false,
      });

      if (successMessage) {
        toast(successMessage, { type: TOAST_TYPE_SUCCESS });
      }
    } catch (err: any) {
      const error = {
        status: err?.response?.status,
        message: err.message,
        errors: err?.response?.data?.errors || [],
      };
      setState({
        ...state,

        // reset previous data so subsequent api calls do not trigger previous success message
        // for example without this if I created a user, a success message would show.
        // then if I created another user where the api call failed, an error message & success message would show
        data: null,
        error,
        loading: false,
      });

      if (ignoreStatusCodes.length
        && err.response
        && err.response.status
        && ignoreStatusCodes.includes(err.response.status)
      ) {
        return;
      }

      if (!ignoreError && err && err.response && err.response.data && err.response.data.message) {
        toast(err.response.data.message, { type: TOAST_TYPE_ERROR });
      } else if (errorMessage && !ignoreError) {
        toast(errorMessage, { type: TOAST_TYPE_ERROR });
      } else {
        throw Error('Api Call Failed');
      }
    }
  };

  useEffect(() => {
    if (config) {
      callApi();
    }

    // cleanup state to avoid memory leaks
    return () => {
      setState({
        error: null,
        loading: false,
        data: null,
      });
    };
  }, [config]);

  useDebugValue(state);

  return {
    ...state,
  };
};

export default useCallApi;
