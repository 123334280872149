import React from 'react';

const UnauthorizedPage = () => {
  return (
    <section className="p-5 text-center">
      <div style={{ fontSize: 96 }}>403</div>
      <p style={{ fontSize: 24 }}>Unauthorized</p>
      <p>You do not have access to this page</p>
    </section>
  );
};

export default UnauthorizedPage;
