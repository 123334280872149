import { useState } from 'react';

import { useToggle } from '@/hooks';
import { IUserResponse } from '@/types/user';

const usePasswordResetLinkModal = () => {
  const { show: showModal, hide: hidePasswordResetLinkModal, isVisible: isPasswordReseLinktModalVisible } = useToggle();
  const [userToPasswordResetLink, setUserToPasswordResetLink] = useState<IUserResponse | null>(null);

  const showPasswordResetLinkModal = (user: IUserResponse) => {
    setUserToPasswordResetLink(user);
    showModal();
  };

  return {
    showPasswordResetLinkModal,
    hidePasswordResetLinkModal,
    isPasswordReseLinktModalVisible,
    userToPasswordResetLink,
  };
};

export default usePasswordResetLinkModal;
