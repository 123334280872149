import { useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useNavigate } from 'react-router-dom';

import { IUserResponse } from '@/types/user';
import { useCallApi } from '@/hooks';

import useMyUser from '@/context/myUser/useMyUser';

const useEmulateUser = () => {
  const routerNavigate = useNavigate();

  const {
    refreshMyUser,
  } = useMyUser();

  // set emulate
  const [emulatedUser, setEmulatedUser] = useState<Record<string, never> | null>(null);
  const [emulateUserApiConfig, setEmulateUserApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: emulateUserResponse,
    loading: emulatedUserIsLoading,
  } = useCallApi(emulateUserApiConfig, {
    successMessage: 'User emulated',
    errorMessage: 'Unable to emulate user',
  });

  const emulateUser = (user: IUserResponse) => {
    setEmulateUserApiConfig({
      url: `/users/${user.id}/set-emulate`,
      method: 'post',
    });
  };
  useEffect(() => {
    if (!emulateUserResponse) return;

    setEmulatedUser({});
    refreshMyUser();
    routerNavigate('/home', {});
  }, [emulateUserResponse]);

  // clear emulate
  const [clearEmulateUser, setClearEmulateUser] = useState<Record<string, never> | null>(null);
  const [clearEmulateApiConfig, setClearEmulateApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: clearEmulateResponse,
    loading: clearEmulateIsLoading,
  } = useCallApi(clearEmulateApiConfig, {
    successMessage: 'User emulation exited',
    errorMessage: 'Unable to exit user emulation',
  });

  const clearEmulate = () => {
    setClearEmulateApiConfig({
      url: '/users/clear-emulate',
      method: 'post',
    });
  };
  useEffect(() => {
    if (!clearEmulateResponse) return;

    setClearEmulateUser({});
    refreshMyUser();

    if (window.location.pathname.includes('/admin/users')) {
      routerNavigate('/admin/users');

      // need to refresh app so page loads properly with
      routerNavigate(0);
    } else {
      routerNavigate('/admin/users');
    }
  }, [clearEmulateResponse]);

  return {
    emulateUser,
    emulatedUser,
    emulatedUserIsLoading,
    clearEmulate,
    clearEmulateUser,
    clearEmulateIsLoading,
  };
};

export default useEmulateUser;
