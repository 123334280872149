import nextLogo from '@/assets/logos/next_logo.png';
import standardSignupScreen from '@/assets/images/standard_signup_screen.png';
import nextSignupScreen from '@/assets/images/next_signup_screen.png';
import { ROLE_ADMIN, ROLE_USER, SIGNUP_TYPE_STANDARD, SIGNUP_TYPE_NEXTLED } from '@/constants';

export const ASSIGNABLE_USER_ROLES = [ROLE_ADMIN, ROLE_USER] as const;

export const STANDARD_SIGNUP_TYPE = {
  value: SIGNUP_TYPE_STANDARD,
  text: 'Standard',
  logo: null,
  screenshot: standardSignupScreen,
};
export const NEXTLED_SIGNUP_TYPE = {
  value: SIGNUP_TYPE_NEXTLED,
  text: 'NextLED',
  logo: nextLogo,
  screenshot: nextSignupScreen,
};

export const ASSIGNABLE_SIGNUP_TYPES = [STANDARD_SIGNUP_TYPE, NEXTLED_SIGNUP_TYPE];
export const ASSIGNABLE_SIGNUP_TYPE_VALUES = [SIGNUP_TYPE_STANDARD, SIGNUP_TYPE_NEXTLED] as const;
export const ASSIGNABLE_SIGNUP_TYPES_DICT = {
  [SIGNUP_TYPE_STANDARD]: STANDARD_SIGNUP_TYPE,
  [SIGNUP_TYPE_NEXTLED]: NEXTLED_SIGNUP_TYPE,
};
