import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { ICompanyResponse } from '@/types/company';
import { useCallApi } from '@/hooks';

const useReadOneCompany = () => {
  const [company, setCompany] = useState<ICompanyResponse | null>(null);
  const [getCompanyApiConfig, setGetCompanyApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readOneCompanyResponse,
    loading: readOneCompanyIsLoading,
  } = useCallApi(getCompanyApiConfig, {
    errorMessage: 'Unable to load company',
  });

  const readOneCompany = (companyId: string) => {
    setGetCompanyApiConfig({
      url: `/companies/${companyId}`,
      method: 'get',
    });
  };

  useEffect(() => {
    setCompany(readOneCompanyResponse?.data);
  }, [readOneCompanyResponse]);

  return {
    readOneCompany,
    company,
    readOneCompanyIsLoading,
  };
};

export default useReadOneCompany;

