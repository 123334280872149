import React from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import Button from 'react-bootstrap/esm/Button';
import Badge from 'react-bootstrap/esm/Badge';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faBuilding, faEllipsisV, faUser } from '@fortawesome/free-solid-svg-icons';

import ConditionalWrapper from '@/components/wrappers/ConditionalWrapper';
import { IUserResponse } from '@/types/user';

interface UserListItemProps {
  user: IUserResponse,
  disableEditRole?: boolean,
  disableEditRoleReason?: string | null,
  disableDeleteUser?: boolean,
  disableDeleteUserReason?: string | null,
  onEditUser: (user: IUserResponse) => void,
  onDelete: (user: IUserResponse) => void,
  onEmulateUser?: (user: IUserResponse) => void,
  onEditSchedulerPermissions?: (user: IUserResponse) => void,
  onCreatePasswordResetLink?: (user: IUserResponse) => void,
  onSendInviteEmail?: (user: IUserResponse) => void,
  onSendPasswordResetEmail?: (user: IUserResponse) => void,
  onClickCompany?: (user: IUserResponse) => void,
}

const userListItem = ({
  user,
  disableEditRole = false,
  disableEditRoleReason,
  disableDeleteUser = false,
  disableDeleteUserReason,
  onEditUser,
  onDelete,
  onEmulateUser,
  onEditSchedulerPermissions,
  onCreatePasswordResetLink,
  onSendInviteEmail,
  onSendPasswordResetEmail,
  onClickCompany,
}: UserListItemProps) => {
  const inviteIsPending = !user.firstName || !user.lastName;

  return (
    <div className="border-bottom d-flex align-items-center">
      <div className="py-3 px-2 flex-grow-1 text-truncate">
        <span className="fw-bold text-truncate">
          { user.email }
          {(inviteIsPending) && (
            <span
              className="ms-2 d-inline-block"
            >
              <Badge>Invited</Badge>
            </span>
          )}
        </span><br />
        <span className="text-muted small">
          {user.fullName || '-'} <br />
          <span
            className={classNames({
              'cursor-pointer text-underline': !!onClickCompany,
            })}
            onClick={() => onClickCompany && onClickCompany(user)}
          >
            <FontAwesomeIcon className="me-2" icon={faBuilding} />
            {user.company?.name || '-'}
          </span><br />
          <FontAwesomeIcon className="me-2" icon={faUser} />
          {user.role}
        </span>
      </div>

      <div className="d-flex">
        {onEmulateUser && (
          <OverlayTrigger
            overlay={<Tooltip>Emulate User</Tooltip>}
          >
            <Button
              className="text-primary me-2 position-relative"
              variant="link"
              onClick={() => onEmulateUser(user)}
            >
              <FontAwesomeIcon icon={faArrowRightFromBracket} />
            </Button>
          </OverlayTrigger>
        )}
        <Dropdown>
          <Dropdown.Toggle
            className="text-dark"
            as={Button}
            variant="link"
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </Dropdown.Toggle>

          <Dropdown.Menu align="end">
            {/* Edit Role */}
            <ConditionalWrapper
              condition={disableEditRole && !!disableEditRoleReason}
              wrapper={(children) => (<>
                <OverlayTrigger
                  overlay={<Tooltip>{disableEditRoleReason}</Tooltip>}
                >
                  <div style={{ cursor: 'not-allowed' }} >
                    {children}
                  </div>
                </OverlayTrigger>
              </>)}
            >
              <Dropdown.Item
                disabled={disableEditRole}
                onClick={() => onEditUser(user)}
              >
                Edit User
              </Dropdown.Item>
            </ConditionalWrapper>
            {/* Emulate User */}
            {(onEmulateUser) && (
              <Dropdown.Item onClick={() => onEmulateUser(user)}>Emulate User</Dropdown.Item>
            )}
            {(onEditSchedulerPermissions) && (
              <Dropdown.Item onClick={() => onEditSchedulerPermissions(user)}>Edit Scheduler Permissions</Dropdown.Item>
            )}
            {(onCreatePasswordResetLink || onSendInviteEmail || onSendPasswordResetEmail) && (
              <hr className="my-2" />
            )}
            {/* Reset Password Link */}
            {(onCreatePasswordResetLink) && (
              <Dropdown.Item onClick={() => onCreatePasswordResetLink(user)}>Create Reset Password Link</Dropdown.Item>
            )}
            {/* Send Invite Email */}
            {(onSendInviteEmail) && (
              <Dropdown.Item onClick={() => onSendInviteEmail(user)}>Send Invite Email</Dropdown.Item>
            )}

            {/* Send Password Reset Email to User */}
            {(onSendPasswordResetEmail) && (
              <Dropdown.Item onClick={() => onSendPasswordResetEmail(user)}>Send Password Reset Email</Dropdown.Item>
            )}
            {/* Remove User */}
            <ConditionalWrapper
              condition={disableDeleteUser && !!disableDeleteUserReason}
              wrapper={(children) => (<>
                <OverlayTrigger
                  overlay={<Tooltip>{disableDeleteUserReason}</Tooltip>}
                >
                  <div style={{ cursor: 'not-allowed' }} >
                    {children}
                  </div>
                </OverlayTrigger>
              </>)}
            >
              <>
                <hr className="my-2" />
                <Dropdown.Item
                  disabled={disableDeleteUser}
                  onClick={() => onDelete(user)}
                >
                  Remove User
                </Dropdown.Item>
              </>
            </ConditionalWrapper>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default userListItem;
