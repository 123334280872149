/* eslint-disable no-process-env */
import axios from 'axios';

const projectContentAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PROJECT_CONTENT_API_URL,

  // set to true to send cookies (needed for emulation)
  withCredentials: true,
});

export default projectContentAxiosInstance;
