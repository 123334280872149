import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { ICompanyResponse, ICompanyUpdateRequest } from '@/types/company';
import { useCallApi } from '@/hooks';

const useUpdateOneCompany = () => {
  const [updatedCompany, setUpdatedCompany] = useState<ICompanyResponse | null>(null);
  const [putCompanyApiConfig, setPutCompanyApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateOneCompanyResponse,
    loading: updateOneCompanyIsLoading,
  } = useCallApi(putCompanyApiConfig, {
    successMessage: 'Company Updated',
    errorMessage: 'Unable to update company',
  });

  const updateOneCompany = (companyToUpdate: ICompanyUpdateRequest) => {
    setPutCompanyApiConfig({
      url: `/companies/${companyToUpdate.id}`,
      method: 'put',
      data: companyToUpdate,
    });
  };

  useEffect(() => {
    setUpdatedCompany(updateOneCompanyResponse?.data);
  }, [updateOneCompanyResponse]);

  return {
    updateOneCompany,
    updatedCompany,
    updateOneCompanyIsLoading,
  };
};

export default useUpdateOneCompany;
