import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IProjectContentCreditsUpdateRequest } from '@/types/company';
import { useCallApi } from '@/hooks';

const useUpdateProjectContentCredits = () => {
  const [updatedProjectContentCredits, setUpdatedProjectContentCredits] = useState<Record<string, never> | null>(null);
  const [putProjectContentCreditsApiConfig, setPutProjectContentCreditsApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: updateProjectContentCreditsResponse,
    loading: updateProjectContentCreditsIsLoading,
  } = useCallApi(putProjectContentCreditsApiConfig, {
    successMessage: 'Company Credits Updated',
    errorMessage: 'Unable to update company credits',
    axios: 'projectContentAxios',
  });

  const updateProjectContentCredits = ({
    id: companyId,
    ...payload
  }: IProjectContentCreditsUpdateRequest) => {
    setPutProjectContentCreditsApiConfig({
      url: `/company/${companyId}/credits`,
      method: 'put',
      data: payload,
    });
  };

  useEffect(() => {
    if (updateProjectContentCreditsResponse) setUpdatedProjectContentCredits({});
  }, [updateProjectContentCreditsResponse]);

  return {
    updateProjectContentCredits,
    updatedProjectContentCredits,
    updateProjectContentCreditsIsLoading,
  };
};

export default useUpdateProjectContentCredits;
