import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useCallApi } from '@/hooks';

const useDeleteOneUser = () => {
  const [deletedUser, setDeletedUser] = useState<Record<string, never> | null>(null);
  const [deleteUserApiConfig, setDeleteUserApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: deleteOneUserResponse,
    loading: deleteOneUserIsLoading,
  } = useCallApi(deleteUserApiConfig, {
    successMessage: 'User Deleted',
    errorMessage: 'Unable to delete user',
  });

  const deleteOneUser = (userId: string) => {
    setDeleteUserApiConfig({
      url: `/users/${userId}`,
      method: 'delete',
    });
  };

  useEffect(() => {
    if (deleteOneUserResponse) setDeletedUser({});
  }, [deleteOneUserResponse]);

  return {
    deleteOneUser,
    deletedUser,
    deleteOneUserIsLoading,
  };
};

export default useDeleteOneUser;
