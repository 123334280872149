import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '@/constants';
import { ProtectedRouteByRoles } from '@/features/Auth';

import AdminCatchAllPage from './components/AdminCatchAllPage';
import CompanyDetailsFromRoute from './components/CompanyDetailsFromRoute';
import UserListPage from './components/UserListPage';
import CompanyListPage from './components/CompanyListPage';
import CompanyDetailsFromUser from './components/CompanyDetailsFromUser';

/**
 * Builds the routes for the signs feature.
 *
 * @returns {*}
 */
export function AdminRoutesSwitch() {
  return (
    <div>
      <Routes>
        <Route path="/users" element={(<ProtectedRouteByRoles allowedRoles={[ROLE_SUPER_ADMIN]}>
          <UserListPage />
        </ProtectedRouteByRoles>)}
        />
        <Route path="/companies" element={(<ProtectedRouteByRoles allowedRoles={[ROLE_SUPER_ADMIN]}>
          <CompanyListPage />
        </ProtectedRouteByRoles>)}
        />
        <Route path="/companies/:companyId" element={(<ProtectedRouteByRoles allowedRoles={[ROLE_SUPER_ADMIN]}>
          <CompanyDetailsFromRoute />
        </ProtectedRouteByRoles>)}
        />
        <Route path="/my-company" element={(<ProtectedRouteByRoles allowedRoles={[ROLE_SUPER_ADMIN, ROLE_ADMIN]}>
          <CompanyDetailsFromUser />
        </ProtectedRouteByRoles>)}
        />
        <Route path="/*" element={<AdminCatchAllPage />} />
      </Routes>
    </div>
  );
}
