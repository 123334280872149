import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { IndexApiParams } from '@/types/api';
import { ICompanyResponse } from '@/types/company';
import { useCallApi } from '@/hooks';

const useReadCompanies = () => {
  const [companies, setCompanies] = useState<ICompanyResponse[]>([]);
  const [companiesTotal, setCompaniesTotal] = useState<number>(0);
  const [getCompanyListApiConfig, setGetCompanyListApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: readCompaniesResponse,
    loading: readCompaniesIsLoading,
  } = useCallApi(getCompanyListApiConfig, {
    errorMessage: 'Unable to load companies',
  });

  const readCompanies = (params: IndexApiParams = {}) => {
    setGetCompanyListApiConfig({
      url: '/companies',
      method: 'get',
      params,
    });
  };

  useEffect(() => {
    if (!readCompaniesResponse) return;

    setCompanies(readCompaniesResponse?.data?.data || []);
    setCompaniesTotal(readCompaniesResponse?.data?.meta?.total || 0);
  }, [readCompaniesResponse]);

  return {
    readCompanies,
    companies,
    companiesTotal,
    readCompaniesIsLoading,
  };
};

export default useReadCompanies;

