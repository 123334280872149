import React from 'react';
import Button from 'react-bootstrap/esm/Button';

import { IScheduleSignResponse } from '@/types/sign';
import { useToggle } from '@/hooks';

import SchedulerSignListItem from './SchedulerSignListItem';

const INITIAL_SIGN_COUNT_TO_SHOW = 5;

interface SchedulerSignListViewProps {
  signs: IScheduleSignResponse[],
  onEdit: (sign: IScheduleSignResponse) => void,
  onDelete: (sign: IScheduleSignResponse) => void,
  canManageSigns?: boolean,
  onClickSign?: (user: IScheduleSignResponse) => void,
}

const SchedulerSignListView = ({
  signs,
  onEdit,
  onDelete,
  canManageSigns = true,
  onClickSign,
}: SchedulerSignListViewProps) => {
  const { toggle: toggleShowMore, isVisible: showMore } = useToggle();

  const signCount = signs.length;

  const signsFiltered = signCount > INITIAL_SIGN_COUNT_TO_SHOW && !showMore
    ? signs.slice(0, INITIAL_SIGN_COUNT_TO_SHOW)
    : signs;

  return (
    <section>
      {(signs.length === 0) && (
        <div className="px-2 py-3">
          No Signs Found
        </div>
      )}
      {signsFiltered.map((sign) => (
        <SchedulerSignListItem
          key={sign.id}
          sign={sign}
          onEdit={onEdit}
          onDelete={onDelete}
          canManageSigns={canManageSigns}
          onClickSign={onClickSign}
        />
      ))}
      {(signCount > INITIAL_SIGN_COUNT_TO_SHOW) && (
        <Button
          className="w-100"
          variant="link"
          onClick={toggleShowMore}
        >
          Show {showMore ? 'Less' : 'More' }
        </Button>
      )}
    </section>
  );
};

export default SchedulerSignListView;
