import { useState } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useCallApi } from '@/hooks';

const useSendInviteEmail = () => {
  const [sendInviteEmailApiConfig, setSendInviteEmailApiConfig] = useState<AxiosRequestConfig | null>(null);
  const {
    data: sendInviteEmailResponse,
    loading: sendInviteEmailIsLoading,
    error: sendInviteEmailError,
  } = useCallApi(sendInviteEmailApiConfig, {
    successMessage: 'Invite email sent successfully',
    errorMessage: 'Unable to send invite email',
  });

  const sendInviteEmail = (userId: string) => {
    setSendInviteEmailApiConfig({
      url: `/users/${userId}/send-invite-email`,
      method: 'post',
    });
  };

  return {
    sendInviteEmail,
    sendInviteEmailResponse,
    sendInviteEmailIsLoading,
    sendInviteEmailError,
  };
};

export default useSendInviteEmail;
