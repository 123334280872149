import { useEffect, useState } from 'react';
import { useToggle } from '@/hooks';
import { toast } from 'react-toastify';

import { IUserResponse } from '@/types/user';
import { useDeleteOneUser } from '@/hooks/user';
import { TOAST_TYPE_ERROR } from '@/constants';

const useUserDeleteModal = () => {
  const { show: showModal, hide: hideUserDeleteModal, isVisible: isUserDeleteModalVisible } = useToggle();
  const [userToDelete, setUserToDelete] = useState<IUserResponse | null>(null);

  const {
    deleteOneUser,
    deleteOneUserIsLoading,
    deletedUser,
  } = useDeleteOneUser();

  const showUserDeleteModal = (user: IUserResponse, myUser: IUserResponse) => {
    if (user.id === myUser?.id) {
      return toast('You cannot delete yourself.', { type: TOAST_TYPE_ERROR });
    }
    setUserToDelete(user);
    return showModal();
  };

  const submitUserDelete = () => {
    if (!userToDelete) return;
    deleteOneUser(userToDelete.id);
  };

  useEffect(() => {
    if (!deletedUser) return;
    hideUserDeleteModal();
  }, [deletedUser]);

  return {
    showUserDeleteModal,
    hideUserDeleteModal,
    isUserDeleteModalVisible,
    submitUserDelete,
    deletedUser,
    deleteOneUserIsLoading,
    userToDelete,
  };
};

export default useUserDeleteModal;
