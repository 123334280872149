import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RoutesSwitch } from './routes';

import '@/styles/App.scss';

/**
 * Base Application Page
 * @returns {function}
 */
function App() {
  return (
    <div className="App">
      <RoutesSwitch />
      <ToastContainer theme="light" autoClose={2000} hideProgressBar />
    </div>
  );
}

export default App;
