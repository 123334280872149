/* eslint-disable no-process-env */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown, faCircleNotch, faUser } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/esm/Button';
import Navbar from 'react-bootstrap/esm/Navbar';
import NavDropdown from 'react-bootstrap/esm/NavDropdown';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

import { useMyUser } from '@/context/myUser';
import { useWindowSize } from '@/hooks';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '@/constants';

import './TopNav.scss';

const BOOTSTRAP_LG_QUERY_IN_PIXELS = 992;

// eslint-disable-next-line no-process-env
const AUTH0_LOGOUT_REDIRECT_URL = process.env.REACT_APP_APP_URL || '';
// eslint-disable-next-line no-process-env
const REACT_APP_WORDPRESS_URL = process.env.REACT_APP_WORDPRESS_URL || '#';

/**
 * Top Navigation
 *
 * @returns {JSX.Element}
 */
export const TopNav = (): JSX.Element => {
  const { logout, isAuthenticated } = useAuth0();
  const { myUser } = useMyUser();
  const windowSize = useWindowSize();

  const windowIsLessThanLarge = windowSize.width < BOOTSTRAP_LG_QUERY_IN_PIXELS;
  const dropdownIcon = windowIsLessThanLarge
    ? faBars
    : faCaretDown;

  const companyName = myUser && myUser.company ? myUser.company.name : '';

  const NavDropdownTitle = (<Button
    className="profile-dropdown-button"
    variant="outline-light"
  >
    <span className="d-none d-lg-inline-block">
      {
        myUser
          ? companyName
          : <FontAwesomeIcon icon={faCircleNotch} spin />
      }
    </span>
    <FontAwesomeIcon icon={dropdownIcon} />
  </Button>);

  return (
    <>
      <Navbar
        className="top-nav"
        expand={false}
      >
        <div
          className="top-nav-logo"
        >
          <a href={REACT_APP_WORDPRESS_URL}>
            <img
              src="/pc_logo.png"
            />
          </a>
        </div>
        <NavDropdown
          className="profile-dropdown"
          title={NavDropdownTitle}
          align="end"
          disabled={!isAuthenticated}
        >
          {(myUser) && (<>
            <NavDropdown.Item
              as="div"
              disabled
            >
              <FontAwesomeIcon
                className="me-2 text-black"
                icon={faUser}
              />
              <span className="text-black">
                {myUser.fullName || '-'}
              </span><br />
              <small className="text-gray">{myUser.email}</small>
            </NavDropdown.Item>
            <hr className="mb-2" />
          </>)}
          {((myUser && (myUser.role === ROLE_SUPER_ADMIN || myUser.role === ROLE_ADMIN))) && (<>
            <NavDropdown.Item
              as={Link}
              to="/admin"
            >
              Admin Dashboard
            </NavDropdown.Item>
            <hr className="my-1" />
          </>)}
          <NavDropdown.Item
            onClick={() => logout({
              logoutParams: {
                returnTo: AUTH0_LOGOUT_REDIRECT_URL,
              },
            })}
          >
            Sign Out
          </NavDropdown.Item>
        </NavDropdown>
      </Navbar>
    </>
  );
};

export default TopNav;
