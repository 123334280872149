import React from 'react';

import { useRequiredParams } from '@/hooks';

import CompanyDetailsPage from './CompanyDetailsPage';

const CompanyDetailsFromRoute = () => {
  const { companyId } = useRequiredParams<{
    companyId: string,
  }>();

  return (<CompanyDetailsPage
    companyId={companyId}
  />);
};

export default CompanyDetailsFromRoute;
