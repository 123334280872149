import { useEffect } from 'react';

import { useToggle } from '@/hooks';
import { useUpdateProjectContentCredits } from '@/hooks/company';
import { IProjectContentCreditsUpdateRequest } from '@/types/company';

const useCompanyEditCreditsModal = () => {
  const { show: showCompanyEditCreditsModal, hide: hideCompanyEditCreditsModal, isVisible: isCompanyEditCreditsModalVisible } = useToggle();

  const {
    updateProjectContentCredits,
    updatedProjectContentCredits: updatedCredits,
    updateProjectContentCreditsIsLoading: updateCreditsIsLoading,
  } = useUpdateProjectContentCredits();

  const submitCreditsUpdate = (company: IProjectContentCreditsUpdateRequest) => {
    updateProjectContentCredits(company);
  };

  useEffect(() => {
    if (!updatedCredits) return;

    hideCompanyEditCreditsModal();
  }, [updatedCredits]);

  return {
    showCompanyEditCreditsModal,
    hideCompanyEditCreditsModal,
    isCompanyEditCreditsModalVisible,
    updatedCredits,
    updateCreditsIsLoading,
    submitCreditsUpdate,
  };
};

export default useCompanyEditCreditsModal;
