import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Layout } from '@/layout';
import { ProtectedRoute, Login, Logout } from '@/features/Auth';
import { AdminRoutesSwitch } from '@/features/Admin';
import { Home } from '@/features/Home';
import NotFoundPage from '@/components/NotFoundPage';

const protectedWithNav = (PageComponent: React.ComponentType) => {
  if (!PageComponent) throw new Error('PageComponent was not specified.');

  const layoutAndPage = () => (<Layout>
    <PageComponent />
  </Layout>);

  return (
    <ProtectedRoute component={layoutAndPage} />
  );
};

/**
 * Builds the routes for the app.
 *
 * @returns {*}
 */
export function RoutesSwitch() {
  return (
    <Routes>
      <Route path="/home" element={protectedWithNav(Home)} />
      <Route path="/admin/*" element={protectedWithNav(AdminRoutesSwitch)} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={protectedWithNav(Logout)} />
      <Route path="/" element={<Navigate to="/home" replace={true} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
