import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownAZ, faArrowDownZA } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import Search from '@/components/Search';
import SimplePagination from '@/components/SimplePagination';
import ConfirmationModal from '@/components/ConfirmationModal';
import Loader from '@/components/Loader';
import { useEmulateUser, useReadUsers } from '@/hooks/user';
import { useMyUser } from '@/context/myUser';
import { usePasswordResetLinkModal, useSendInviteEmailModal, useSendPasswordResetEmailModal, useUserDeleteModal, useUserEditModal } from '@/features/Admin/hooks';
import { sortGroup, sortOption } from '@/types/dropdown';
import { useReadSchedulerSigns } from '@/hooks/sign';
import useSchedulerPermissionsModal from '@/features/Admin/hooks/useSchedulerPermissionsModal';

import UserListView from './UserListView';
import UserEditModal from './UserEditModal';
import PasswordResetLinkModal from './PasswordResetLinkModal';
import SchedulerPermissionsModal from './SchedulerPermissionsModal';

const SORT_GROUPS: sortGroup[] = [
  {
    name: 'Name',
    options: [
      {
        name: 'A - Z',
        field: 'name',
        direction: 'asc',
        icon: faArrowDownAZ,
      },
      {
        name: 'Z - A',
        field: 'name',
        direction: 'desc',
        icon: faArrowDownZA,
        hasDividerAfterItem: true,
      },
    ],
  },
  {
    name: 'Email',
    options: [
      {
        name: 'A - Z',
        field: 'email',
        direction: 'asc',
        icon: faArrowDownAZ,
      },
      {
        name: 'Z - A',
        field: 'email',
        direction: 'desc',
        icon: faArrowDownZA,
        hasDividerAfterItem: true,
      },
    ],
  },
];

const PER_PAGE = 40;

const UserListPage = () => {
  const { myUser } = useMyUser();
  const routerNavigate = useNavigate();

  const [searchString, setSearchString] = useState<string>('');
  const [sortValue, setSortValue] = useState<sortOption>(SORT_GROUPS[0].options[0]);
  const [page, setPage] = useState(1);

  const {
    showUserEditModal,
    hideUserEditModal,
    isUserEditModalVisible,
    submitUserEdit,
    updateUserIsLoading,
    updatedUser,
    userToEdit,
  } = useUserEditModal();

  const {
    showPasswordResetLinkModal,
    hidePasswordResetLinkModal,
    isPasswordReseLinktModalVisible,
    userToPasswordResetLink,
  } = usePasswordResetLinkModal();

  const {
    showUserDeleteModal,
    hideUserDeleteModal,
    isUserDeleteModalVisible,
    submitUserDelete,
    deletedUser,
    userToDelete,
    deleteOneUserIsLoading,
  } = useUserDeleteModal();

  const {
    showSendInviteEmailModal,
    hideSendInviteEmailModal,
    isSendInviteEmailModalVisible,
    submitSendInviteEmail,
    userToSendInviteEmail,
    sendInviteEmailIsLoading,
  } = useSendInviteEmailModal();

  const {
    showPasswordResetEmailModal,
    hidePasswordResetEmailModal,
    submitSendPasswordResetEmail,
    isPasswordResetEmailModalVisible,
    sendPasswordResetEmailIsLoading,
    userToSendPasswordResetEmail,
  } = useSendPasswordResetEmailModal();

  const {
    emulateUser,
  } = useEmulateUser();

  const {
    readUsers,
    users,
    usersTotal,
    readUsersIsLoading,
  } = useReadUsers();

  const {
    readSchedulerSigns,
    schedulerSigns,
  } = useReadSchedulerSigns();

  const {
    showSchedulerPermissionsModal,
    hideSchedulerPermissionsModal,
    isSchedulerPermissionsModalVisible,
    userToEditPermissions,
    submitSchedulerPermissions,
    updateSchedulerSignPermissionsIsLoading,
    updatedPermissions,
  } = useSchedulerPermissionsModal();

  useEffect(() => {
    readUsers({
      search: searchString !== '' ? searchString : undefined,
      sort: sortValue.field,
      direction: sortValue.direction,
      page,
      perPage: PER_PAGE,
    });
  }, [searchString, sortValue, page, updatedUser, deletedUser]);

  useEffect(() => {
    if (!updatedPermissions) return;
    hideSchedulerPermissionsModal();
  }, [updatedPermissions]);

  if (!myUser) return <></>;

  return (
    <div>
      <div className="px-3 py-4 bg-white shadow-sm">
        <Search
          setSearchString={(searchValue) => {
            setPage(1);
            setSearchString(searchValue);
          }}
          searchString={searchString}
          placeholder="Search all Users by name, email, or company name"
          searchId="search-users"
        />
        <div className="d-flex justify-content-end mt-3">
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-primary"
            >
              <FontAwesomeIcon icon={sortValue.icon} />
            </Dropdown.Toggle>
            <Dropdown.Menu
              align="end"
            >
              {SORT_GROUPS.map((group, groupIndex) => {
                return (
                  <div key={group.name}>
                    <div className="px-2 py-1 text-gray">{group.name}</div>
                    {(group.options.map((option) => (
                      <Dropdown.Item
                        key={option.name}
                        onClick={() => setSortValue(option)}
                        active={option.field === sortValue.field && option.name === sortValue.name}
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          icon={option.icon}
                        />
                        <span>{option.name}</span>
                      </Dropdown.Item>
                    )))}
                    {(groupIndex !== SORT_GROUPS.length - 1) && (
                      <Dropdown.Divider />
                    )}
                  </div>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="mt-2 px-3 py-4 bg-white shadow-sm">
        <div className="row border-bottom pb-1">
          <div className="col-12 col-sm-6 pb-2 d-flex align-items-center">
            <h2 className="h5 mb-0">
              Users <small>({ usersTotal.toLocaleString() })</small>
            </h2>
          </div>
          <div className="col-12 col-sm-6 pb-2 d-flex justify-content-end">
            <SimplePagination
              total={usersTotal}
              page={page}
              perPage={PER_PAGE}
              onPage={(newPage) => {
                setPage(newPage);
              }}
            />
          </div>
        </div>
        {(readUsersIsLoading)
          ? (<div className="text-center px-5 py-3">
            <Loader size="2x" />
          </div>)
          : (
            <UserListView
              users={users}
              onEditUser={showUserEditModal}
              onEmulateUser={emulateUser}
              onEditSchedulerPermissions={(user) => {
                readSchedulerSigns({ companyId: user.companyId });
                showSchedulerPermissionsModal(user);
              }}
              onCreatePasswordResetLink={showPasswordResetLinkModal}
              onSendInviteEmail={showSendInviteEmailModal}
              onSendPasswordResetEmail={showPasswordResetEmailModal}
              onDelete={(user) => showUserDeleteModal(user, myUser)}
              onClickCompany={(user) => routerNavigate(`/admin/companies/${user.company.id}`)}
            />
          )}
        <div className="mt-3 d-flex justify-content-end">
          <SimplePagination
            total={usersTotal}
            page={page}
            perPage={PER_PAGE}
            onPage={(newPage) => {
              setPage(newPage);
            }}
          />
        </div>
      </div>

      {(userToEdit) && (<UserEditModal
        isVisible={isUserEditModalVisible}
        onHide={hideUserEditModal}
        user={userToEdit}
        onSubmit={(user) => submitUserEdit(user, users)}
        isSubmitting={updateUserIsLoading}
      />)}
      {(userToEditPermissions) && (<SchedulerPermissionsModal
        isVisible={isSchedulerPermissionsModalVisible}
        onHide={hideSchedulerPermissionsModal}
        user={userToEditPermissions}
        schedulerSigns={schedulerSigns}
        isSubmitting={updateSchedulerSignPermissionsIsLoading}
        onSubmit={submitSchedulerPermissions}
      />)}
      <PasswordResetLinkModal
        isVisible={isPasswordReseLinktModalVisible}
        onHide={hidePasswordResetLinkModal}
        user={userToPasswordResetLink}
      />
      <ConfirmationModal
        show={isUserDeleteModalVisible}
        title="Confirm Delete"
        onHide={hideUserDeleteModal}
        onConfirmation={submitUserDelete}
        confirmButtonColor="danger"
        isSubmitting={deleteOneUserIsLoading}
      >
        Are you sure you want to delete the user <strong>{userToDelete?.email || '-'}</strong>? This user will be permanently deleted.
      </ConfirmationModal>

      <ConfirmationModal
        show={isSendInviteEmailModalVisible}
        title="Confirm Invite Email Send"
        onHide={hideSendInviteEmailModal}
        onConfirmation={submitSendInviteEmail}
        isSubmitting={sendInviteEmailIsLoading}
      >
        Are you sure you want to send an invite email to <strong>{userToSendInviteEmail?.email || '-'}</strong>?
      </ConfirmationModal>

      <ConfirmationModal
        show={isPasswordResetEmailModalVisible}
        title="Confirm Password Reset Send"
        onHide={hidePasswordResetEmailModal}
        onConfirmation={submitSendPasswordResetEmail}
        isSubmitting={sendPasswordResetEmailIsLoading}
      >
        Are you sure you want to send a password reset email to <strong>{userToSendPasswordResetEmail?.email || '-'}</strong>?
      </ConfirmationModal>
    </div>
  );
};

export default UserListPage;
